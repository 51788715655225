/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import { ButtonProps } from 'antd-button-color';
import React, { useContext } from 'react';
import { IhmContext } from '../../context/ihm.context';
import { useLoading } from '../../hooks/use-loading';
import { ResponsiveButton } from '../input/ResponsiveButton';
import * as Locale from '../locale';

/**
 * Simple Button with text
 * @param tkey
 * @param props
 * @constructor
 */
export function TextButton({ tkey, ...props }: ButtonProps & { tkey: string }) {
    const { ihmState, startDebounced, completeDebounced } = useContext(IhmContext);
    const { doAction, loading } = useLoading();

    return (
      <ResponsiveButton
        loading={ihmState.waitingDebouned || loading}
        css={css`max-width: 100%;position: relative; border: none`}
        data-testid={tkey}
        {...props}
        onClick={async (e) => doAction(async () => {
        startDebounced();
        try {
            if (props.onClick) {
                await props.onClick(e);
            }
        } finally {
            completeDebounced();
        }
    })}
      >
        <span css={css`max-width: 100%; text-overflow: ellipsis`}><Locale.Button tkey={tkey} /></span>
      </ResponsiveButton>
);
}
