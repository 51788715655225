import {
    IAcademicReferentReview,
    IComments,
    IDocument,
    IExaminerReview,
    IJuryReview,
    IMissingFieldsList,
    IProgramLine,
    ISecretary,
    ISpecializedSecretaryReview,
} from '@fstn/ecandidaturev2_api-interfaces';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/packages/sorty';
import { TypeColumn } from '@inovua/reactdatagrid-community/types';
import { TypeFilterValue } from '@inovua/reactdatagrid-community/types/TypeFilterValue';
import moment, { Moment } from 'moment';
import { ISchool, ISchoolTrack, ISchoolTrackType } from '@fstn/ecandidaturev2_api-interfaces';

export const dateTypes = {
    type: 'date',
    emptyValue: '',
    operators: [
        {
            name: 'after',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => (filterValue
                    ? moment(moment(value).format(dateFormat), dateFormat)
                        .isAfter(moment(filterValue, dateFormat))
                    : true),
        },
        {
            name: 'afterOrOn',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => (filterValue != null
                    ? moment(moment(value).format(dateFormat), dateFormat)
                        .isSameOrAfter(moment(filterValue, dateFormat))
                    : true),
        },
        {
            name: 'before',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => (filterValue != null
                    ? moment(moment(value).format(dateFormat), dateFormat)
                        .isBefore(moment(filterValue, dateFormat))
                    : true),
        },
        {
            name: 'beforeOrOn',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => (filterValue != null
                    ? moment(moment(value).format(dateFormat), dateFormat)
                        .isSameOrBefore(moment(filterValue, dateFormat))
                    : true),
        },
        {
            name: 'eq',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => (filterValue
                    ? moment(moment(value).format(dateFormat), dateFormat)
                        .isSame(moment(filterValue, dateFormat))
                    : true),
        },
        {
            name: 'neq',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => (filterValue
                    ? !moment(moment(value).format(dateFormat), dateFormat)
                        .isSame(moment(filterValue, dateFormat))
                    : true),
        },
        {
            name: 'inrange',
            fn: ({
 value, filterValue, data, column: { dateFormat },
}) => {
                const { start, end } = filterValue || {};
                if (start && end) {
                    return (
                        moment(moment(value).format(dateFormat), dateFormat)
                            .isSameOrAfter(moment(start, dateFormat))
                        && moment(moment(value).format(dateFormat), dateFormat)
                            .isSameOrBefore(moment(end, dateFormat))
                    );
                }
                if (start) {
                    return moment(moment(value).format(dateFormat), dateFormat)
                        .isSameOrAfter(moment(start, dateFormat));
                }
                if (end) {
                    return moment(moment(value).format(dateFormat), dateFormat)
                        .isSameOrBefore(moment(end, dateFormat));
                }
                return true;
            },
        },
    ],
};

export const numberTypes = {
    type: 'number',
    emptyValue: null,
    operators: [
        {
            name: 'gt',
            fn: ({
                     value,
                     filterValue,
                     data,
                 }: {
                value: number;
                filterValue?: number;
                data: any;
            }): boolean => (filterValue != null ? value > filterValue : true),
        },
        {
            name: 'gte',
            fn: ({
                     value,
                     filterValue,
                     data,
                 }: {
                value: number;
                filterValue?: number;
                data: any;
            }): boolean => (filterValue != null ? value >= filterValue : true),
        },
        {
            name: 'lt',
            fn: ({
                     value,
                     filterValue,
                     data,
                 }: {
                value: number;
                filterValue?: number;
                data: any;
            }): boolean => (filterValue != null ? value < filterValue : true),
        },
        {
            name: 'lte',
            fn: ({
                     value,
                     filterValue,
                     data,
                 }: {
                value: number;
                filterValue?: number;
                data: any;
            }): boolean => (filterValue != null ? value <= filterValue : true),
        },
        {
            name: 'eq',
            fn: ({
                     value,
                     filterValue,
                     data,
                 }: {
                value: number;
                filterValue?: number;
                data: any;
            }): boolean => (filterValue != null ? value === filterValue : true),
        },
        {
            name: 'neq',
            fn: ({
                     value,
                     filterValue,
                     data,
                 }: {
                value: number;
                filterValue?: number;
                data: any;
            }): boolean => (filterValue != null ? value !== filterValue : true),
        },
    ],
};

export type CandidateRow = {
    avatar: IDocument,
    commentsBtwSecretaries: string,
    commentsBtwAllTypesOfSecretaries: string,
    specializedSecretaryReviewComments: IComments[],
    countProgram: number,
    id: string,
    shortId: string,
    firstName: string,
    lastName: string,
    email: string,
    lastDegreeName: ISchoolTrack,
    lastSchoolName: ISchool,
    lastSchoolTrackType: ISchoolTrackType,
    createdAt: Date,
    connectedAt: Date,
    updatedAt: Date,
    submittedAt: Date,
    candidateFileStatus: string,
    particularCase: boolean,
    ignoreIt: boolean,
    disabled: boolean,
    examinerReview: IExaminerReview,
    examinerReviewReview: string,
    examinerReviewNotation: number,
    referentReview: IAcademicReferentReview,
    programLines: IProgramLine[];
    specializedSecretaryReviews: ISpecializedSecretaryReview,
    lastUpdatedAt: Date,
    lastUpdatedBy: string,
    missingFieldsList: IMissingFieldsList,
    secretaryLockedBy: ISecretary,
    secretaryLockedAt: Date,
    juryReviewStatus: string,
    juryReviewLastPublishedStatus: string,
    juryReviews: IJuryReview[],
    juryReviewUpdatedAt: Date,
    juryReviewPublicatedAt: Date,
};

export type CandidateFilesStateType = {
    sorter: TypeSortInfo;
    search: any[],
    selectedCandidateFiles: CandidateRow[],
    data: CandidateRow[],
    filteredData: CandidateRow[],
    fetchedAt: Moment,
    hackReFetchData: number,
    filter: TypeFilterValue,
    loading: boolean,
    columns: TypeColumn[],
    columnOrder?: string[],
    particularCase: boolean,
    lastQuery: string,
    ignoreItCase: boolean,
    filteredCount: number,
    totalCount: number,
    count: number,
    stats: any

};

const existingFilters = ReactDataGrid.defaultProps.filterTypes;

existingFilters.number = numberTypes;
existingFilters.date = dateTypes;
export const filterTypes = {
 ...existingFilters,
    filterStringWithoutNotContainsAndEmpty: {
        name: 'filterStringWithoutNotContainsAndEmpty',
        type: 'string',
        emptyValue: '',
        operators:[
            {name:"contains"},
            //{"name":"notContains"},
            {"name":"eq"},
            //{"name":"neq"},
            //{"name":"empty","filterOnEmptyValue":true,"valueOnOperatorSelect":"","disableFilterEditor":true},
            {"name":"notEmpty","filterOnEmptyValue":true,"valueOnOperatorSelect":"","disableFilterEditor":true},
            {"name":"startsWith"},
            {"name":"endsWith"}]
    },
    countSchoolTracks: {
        name: 'countSchoolTracks',
        operators: [
            {
                name: 'countSchoolTracks',
                fn: ({ value, filterValue, data }) => {
                    if (!filterValue) {
                        return true;
                    }
                    return data.countSchoolTrack?.some((p) => p.id?.toLowerCase().indexOf(filterValue?.id?.toLowerCase()) !== -1);
                },
            },
        ],
    },
    listPrograms: {
        name: 'listPrograms',
        operators: [
            {
                name: 'listPrograms',
                fn: ({ value, filterValue, data }) => {
                    if (!filterValue) {
                        return true;
                    }
                    return data.program === filterValue.id;
                },
            },
        ],
    },
    listSchools: {
        name: 'listSchools',
        operators: [
            {
                name: 'listSchools',
                fn: ({ value, filterValue, data }) => {
                    if (!filterValue) {
                        return true;
                    }
                    return data.school === filterValue.id;
                },
            },
        ],
    },
};
