/** @jsx jsx */
import { IAcademicReferentReview } from '@fstn/ecandidaturev2_api-interfaces';
import { useImmer } from 'use-immer';
import React, { Fragment, useContext } from 'react';
import { useDeepCompareCallback, useDeepCompareEffect } from 'use-deep-compare';
import UUID from 'uuid-js';
import { Box, Flex } from 'rebass';
import { css, jsx } from '@emotion/core';
import Space from 'antd/lib/space';
import { Form } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import { DeepPartial } from 'redux';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { EntityFormListContext } from '../../context/entityFormList.context';
import { useValidation } from '../../hooks/use-validation.hook';
import { useResponsiveForm } from '../../hooks/use-responsive-form.hook';
import { useEntity } from '../../hooks/use-entity';
import { CandidateFileContext, CandidateFileContextProviderType } from '../canSeeCandidateFile/CandidateFileContext';
import { EntityFormContext, EntityFormContextType } from '../../context/entityForm.context';
import { ValidationsContext } from '../../context/validations.context';
import { FormContentPanelContainer } from '../../common/form/formContentPanel/FormContentPanelContainer';
import { YesNoFormItem } from '../../common/form/inputFormItems/YesNoFormItems';
import * as Locale from '../../common/locale';
import { InputFormItem } from '../../common/form/inputFormItems/InputFormItem';
import { FixSelectFormItem } from '../../common/form/inputFormItems/FixSelectFormItem';
import { InputNumberFormItem } from '../../common/form/inputFormItems/InputNumberFormItem';
import { InputMaskedFormItem } from '../../common/form/inputFormItems/InputMaskedFormItem';
import { FixRadioFormItem } from '../../common/form/inputFormItems/FixRadioFormItem';
import { InputTextAreaFormItem } from '../../common/form/inputFormItems/InputTextAreaFormItem';
import { UploadFormItemContainer } from '../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { DatePickerFormItem } from '../../common/form/inputFormItems/DatePickerFormItem';
import { IsReferent } from '../user/conditionnal/IsReferent';
import { ButtonsFormItemLayout } from '../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { TextOkButton } from '../../common/button/TextOkButton';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { sNavigate } from '../../utils/safeNavigation';
import { ROUTES } from '../user/UserRoutesDef';
import { FormStyle } from '../../common/form/FormStyle';
import { CandidateCard } from '../canSeeCandidateFile/CandidateCard';
import { CandidateFilePrograms } from '../canSeeCandidateFile/CandidateFilePrograms';
import { WithInitialLoad } from '../../common/withExtraParameters/WithInitialLoad';
import { EntityFormContainer } from '../../common/form/entityForm/EntityFormContainer';
import { DisabledContextProviderAlwaysEnabled } from '../../common/rights/DisabledContextProviderAlwaysEnabled';
import { FinalReview } from './FinalReview';
import { StrictLoading } from '../../common/indicator/SmartLoading';

// SHort name because of column size limitations
const entity = 'a-r-review';
export function ReferentReviewEntityForm() {
  const field = useScopedSelector<any[], any>('IAcademicReferentReview', EntityFormListContext, (c) => c[0].field);
  const formListPropertyName = useScopedSelector<any[], any>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.propertyName);
  const formListEntityId = useScopedSelector<any[], string>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.id);
  const givenBySchool = useScopedSelector<any[], boolean>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.givenBySchool);
  const subjectScope = useScopedSelector<any[], boolean>('IAcademicReferentReview', EntityFormListContext, (c) => c[0]?.entityValue?.subjectScope);

  const { validate } = useValidation();
  const responsive = useResponsiveForm();
  const [state, updateState] = useImmer({ alreadyValidated: false });
  const { putEntity } = useEntity<any>();
  const entityValue = useScopedSelector<any[], EntityFormContextType<IAcademicReferentReview>>('ReferentReviewEntityForm', EntityFormContext, (c) => c[0]?.entityValue);
  const withUpdateValidations = useScopedSelector('ReferentReviewEntityForm', ValidationsContext, (c) => c.withUpdateValidations);
  const steps = useScopedSelector('ReferentReviewEntityForm', ValidationsContext, (c) => c.steps);
  const submit = useScopedSelector<any[], any>('ReferentReviewEntityForm', EntityFormContext, (c) => c[0].submit);

  useDeepCompareEffect(() => {
    async function load() {
      await validate(entity, entityValue, withUpdateValidations, steps);
    }
    if (!state.alreadyValidated) {
      updateState((draft) => {
        draft.alreadyValidated = true;
      });
      load().then(() => { });
    }
  }, [entityValue, state.alreadyValidated, updateState, validate]);
  // loop add formContext.entityValue, state.alreadyValidated, updateState, validate

  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'reviewDocument',
    type: 'reviewDocument',
    parentType: entity,
    parentId: formListEntityId,
  }), [entity, formListEntityId]);

  function getPropertyName(name) {
    if (field) {
      return [formListPropertyName, field.name, name];
    }
    return name;
  }
  const currentYear = new Date().getFullYear();
  return (
    <StrictLoading loading={!formListEntityId} context="ReferentReviewEntityForm">
      <Flex sx={{ flexWrap: 'wrap' }} className="referent-review-container">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <div css={css`padding: 1em;`}>
              <YesNoFormItem
                propertyName={getPropertyName('subjectScope')}
                yesLabel={<Locale.Button tkey="referent.subjectScope.yes" />}
                noLabel={<Locale.Button tkey="referent.subjectScope.no" />}
              />

            </div>

            {(subjectScope === true || subjectScope === false)
              && (
                <Fragment>

                  <div css={css`padding: 1em;`}>
                    {subjectScope
                      && <InputFormItem propertyName={getPropertyName('subject')} />}

                    {!subjectScope
                      && <YesNoFormItem propertyName={getPropertyName('givenBySchool')} />}

                  </div>

                  {(givenBySchool !== true
                    || subjectScope === true)
                    && (
                      <Fragment>

                        <Space direction="vertical" css={css`width:100%`}>
                          <div css={css`padding: 1em;`}>
                            <FixSelectFormItem
                              propertyName={getPropertyName('since')}
                              options={[
                                { value: currentYear, label: `${currentYear - 1}-${currentYear}` },
                                {
                                  value: currentYear - 1,
                                  label: `${currentYear - 2}-${currentYear - 1}`,
                                },
                                {
                                  value: currentYear - 2,
                                  label: `${currentYear - 3}-${currentYear - 2}`,
                                },
                                {
                                  value: currentYear - 3,
                                  label: `${currentYear - 4}-${currentYear - 3}`,
                                },
                              ]}
                            />

                          </div>
                          <div
                            className="referent-review-notation-container"
                            css={css`
padding: 1em;
padding-right: 0em;
display: flex;
flex-wrap: wrap;
gap: 1em;
>.ant-form-item{
  width: calc( 33% - .5em);
  min-width: calc( 33% - .5em);
  max-width: calc( 33% - .5em);
  flex: 1;
}
>.label{
  align-items: center;
    display: flex;
}
`}
                          >
                            <div style={{ width: 'calc( 100% + -1em )' }}>
                              <Form.Item>
                                <Locale.Help
                                  tkey="academic-referent-review.average"
                                />
                              </Form.Item>
                            </div>
                            <div className="ant-form-item label" />
                            <div className="ant-form-item label"><Locale.Label tkey="year1" /></div>
                            <div className="ant-form-item label"><Locale.Label tkey="year2" /></div>
                            <div className="ant-form-item label"><Locale.Label tkey="candidate" /></div>
                            <div className="ant-form-item">
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y1s1GenCandidateAverage')}
                              />
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y1s2GenCandidateAverage')}
                              />
                            </div>
                            <div className="ant-form-item">
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y2s1GenCandidateAverage')}
                              />
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y2s2GenCandidateAverage')}
                              />
                            </div>

                            <div className="ant-form-item label"><Locale.Label tkey="promotion" /></div>
                            <div className="ant-form-item">
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y1s1GenPromotionAverage')}
                              />
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y1s2GenPromotionAverage')}
                              />
                            </div>
                            <div className="ant-form-item">
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y2s1GenPromotionAverage')}
                              />
                              <InputNumberFormItem
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y2s2GenPromotionAverage')}
                              />
                            </div>

                            <div className="ant-form-item label"><Locale.Label tkey="ranking" /></div>
                            <div className="ant-form-item">
                              <InputMaskedFormItem
                                maskFormat="111/111"
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y1s1Rank')}
                              />
                              <InputMaskedFormItem
                                maskFormat="111/111"
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y1s2Rank')}
                              />
                            </div>
                            <div className="ant-form-item">
                              <InputMaskedFormItem
                                maskFormat="111/111"
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y2s1Rank')}
                              />
                              <InputMaskedFormItem
                                maskFormat="111/111"
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 8 }}
                                propertyName={getPropertyName('y2s2Rank')}
                              />
                            </div>
                          </div>
                          <div css={css`padding: 1em;`}>
                            <FixRadioFormItem
                              wrapperCol={{ span: 24 }}
                              labelCol={{ span: 24 }}
                              propertyName={getPropertyName('wideRank')}
                              options={[
                                { value: 'rank.5', label: <Locale.Label tkey="rank.five" /> },
                                { value: 'rank.10', label: <Locale.Label tkey="rank.ten" /> },
                                { value: 'rank.25', label: <Locale.Label tkey="rank.twentyfive" /> },
                                { value: 'rank.50', label: <Locale.Label tkey="rank.fifty" /> },
                                { value: 'rank.*50', label: <Locale.Label tkey="rank.starfifty" /> },
                                { value: 'rank.+50', label: <Locale.Label tkey="rank.plusfifty" /> },
                              ]}
                            />
                          </div>
                          <div css={css`padding: 1em;`}>
                            <Form.Item
                              style={{ marginBottom: '1em !important' }}
                              label={<Locale.Label tkey="academic-referent-review.creteria" />}
                            >
                              <Locale.Label tkey="academic-referent-review.score" />
                              :
                            </Form.Item>
                            <FixRadioFormItem
                              propertyName={getPropertyName('abstractReasoning')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('understandingSpeed')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('practicalSkills')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('autonomy')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('selfTrain')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('workCapacity')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('teamWork')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />
                            <FixRadioFormItem
                              propertyName={getPropertyName('interpersonalSkills')}
                              options={[
                                { value: 'veryWell', label: <Locale.Label tkey="veryWell" /> },
                                { value: 'good', label: <Locale.Label tkey="good" /> },
                                { value: 'way', label: <Locale.Label tkey="way" /> },
                                { value: 'poor', label: <Locale.Label tkey="poor" /> },
                              ]}
                            />

                          </div>
                          <div css={css`padding: 1em;`}>
                            <InputTextAreaFormItem
                              wrapperCol={{ span: 24 }}
                              labelCol={{ span: 24 }}
                              propertyName={getPropertyName('comments')}
                            />
                          </div>

                          <div css={css`padding: 1em;`}>
                            <FinalReview propertyName={getPropertyName('finalReview')} />
                          </div>

                        </Space>
                      </Fragment>
                    )}
                  <div css={css`padding: 1em;`}>
                    <UploadFormItemContainer
                      onAfterChange={async () => {
                        await putEntity('comments/onUpdateAcademicReferentReviewDocument', { id: formListEntityId }, { referentReviewId: formListEntityId });
                      }}
                      accept={'image/*,.pdf'}
                      propertyName={getPropertyName('reviewDocument')}
                      factory={addDocument}
                    />
                    {givenBySchool
                      && (
                        <Fragment>
                          <DatePickerFormItem
                            onAfterChange={async () => {
                              /**
                               * Need this to be sure that data exists on server
                               */
                              setTimeout(async () => {
                                await putEntity('comments/onUpdateAcademicReferentReviewDate', { id: formListEntityId }, { referentReviewId: formListEntityId });
                              }, 1000);
                            }}
                            propertyName={getPropertyName('juryDate')}
                          />
                        </Fragment>
                      )}
                  </div>

                  <IsReferent>
                    <ButtonsFormItemLayout>
                      <Popconfirm
                        title={<Locale.Title tkey="academic-referent-review.complete.confirm" />}
                        onConfirm={submit}
                        okText={<Locale.Button tkey="ok" />}
                        cancelText={<Locale.Button tkey="cancel" />}
                      >
                        <TextOkButton tkey="academic-referent-review.complete" />
                      </Popconfirm>
                    </ButtonsFormItemLayout>
                  </IsReferent>
                </Fragment>
              )}
          </FormContentPanelContainer>
        </Box>
      </Flex>
    </StrictLoading>
  );
}

export function ReferentReviewForm({ academicReferentReviewId }: any) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const { updateCandidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);

  async function complete(values: IAcademicReferentReview) {
    if (values.completed) {
      await axios.put(`a-r-review/${values.id}/update`, {});
    } else {
      await axios.put(`a-r-review/${values.id}/complete`, {});
    }

    /**
     * Remove selected file to display all candidateFile on the referent list not only the selected one
     */
    updateCandidateFileContext(((draft) => {
      draft.selectedCandidateFile = undefined;
    }));
    sNavigate(ROUTES.REFERENT_BASE);
  }
  const onBeforeValidate = (values: IAcademicReferentReview, differences: DeepPartial<IAcademicReferentReview>, setFieldsValue) => {
    if (values?.subjectScope) {
      differences.givenBySchool = null;
      // @ts-ignore
      differences.juryDate = null;
      setFieldsValue({ values, givenBySchool: null, juryDate: null });
    }
    if (!values?.givenBySchool) {
      // @ts-ignore
      differences.juryDate = null;
      setFieldsValue({ values, juryDate: null });
    }
    return differences;
  };

  return (
    <FormStyle>
      <div css={css`.message{background-color: whitesmoke}`}>
        <CandidateCard />
        <CandidateFilePrograms />
      </div>
      <WithInitialLoad entity={entity} entityId={academicReferentReviewId}>
        <EntityFormContainer listeners={{ onBeforeValidate }} onFinish={complete}>
          <DisabledContextProviderAlwaysEnabled>
            <ReferentReviewEntityForm />
          </DisabledContextProviderAlwaysEnabled>
        </EntityFormContainer>
      </WithInitialLoad>
    </FormStyle>
  );
}
