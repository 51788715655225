/** @jsx jsx */
import {jsx} from '@emotion/core';
import {Fragment, useContext} from 'react';
import {Importer, ImporterField} from 'react-csv-importer-v2';

/**
 * LAURENT
 * DOCUMENTATION: npm i react-csv-importer-v2
 */
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer-v2/dist/index.css';
import {useImmer} from 'use-immer';
import {AxiosContext, AxiosContextType} from '../../../../context/axios.context';
import {sNavigateReload} from '../../../../utils/safeNavigation';
import {ADMIN} from '../../../user/UserRoutesDef';
import {SchoolTrackTypeListPage} from './SchoolTrackTypeListPage';
import {ResultPanel} from '../../common/ResultPanel';
import {ButtonDeleteAll} from '../../common/ButtonDeleteAll';

export function SchoolTrackTypeImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      <Importer
        delimiter=";"
        // restartable // optional, lets user choose to upload another file when import is complete
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('/import/school-track-type', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = `${response.data} familles de filières importées`;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
        onClose={async () => {
          sNavigateReload(ADMIN.SCHOOL_TRACK_TYPE_IMPORTER);
        }}
      >
        <ImporterField name="id" label="Id" />
        <ImporterField name="year1" label="Year1" />
        <ImporterField name="year2" label="Year2" />
        <ImporterField name="year3" label="Year3" />
        <ImporterField name="year4" label="Year4" />
        <ImporterField name="year5" label="Year5" />
        <ImporterField name="year6" label="Year6" />
        <ImporterField name="year7" label="Year7" />
        <ImporterField name="year8" label="Year8" />
      </Importer>
      <br />
      <ButtonDeleteAll message="familles de filières supprimées sur" url="/api/admin/school-track-type/deleteAll" updateState={updateState} />
      <ResultPanel error={state.error} result={state.result} redirect={ADMIN.SCHOOL_TRACK_TYPE_IMPORTER} />
      <br />
      <span><h1>Liste des familles de filières</h1></span>
      <SchoolTrackTypeListPage />
    </Fragment>
  );
}
