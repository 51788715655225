/** @jsx jsx */
import { IPayment, IPaymentsList, walkEntityPropertyAndMapDateStrAsMoment } from '@fstn/ecandidaturev2_api-interfaces';
import { css, jsx } from '@emotion/core';
import KRGlue from '@lyracom/embedded-form-glue';
import { Card, Spin } from 'antd';
import Modal from 'antd/lib/modal';
import React, { Fragment, useContext, useLayoutEffect } from 'react';
import { useImmer } from 'use-immer';
import * as Locale from '../../../common/locale';
import { AxiosContext } from '../../../context/axios.context';
import { useEntity } from '../../../hooks/use-entity';
import { CandidateChat } from '../../candidate/CandidateChat';
import { PaymentHistory } from './PaymentHistory';

export function ExtraPaymentForm(props: { payment: IPayment }) {
  const [state, updateState] = useImmer({
    promiseError: null,
    formToken: undefined,
    loaded: false,
    loading: false,
    paymentsDetails: undefined,
    paymentsList: undefined as IPaymentsList,

  });
  const { axios } = useContext<any>(AxiosContext);
  const { loadEntity } = useEntity();

  const endpoint = 'https://api.'
    + 'systempay.fr';
  let publicKey = '96555040:publickey_QAqbYCfuO83IndxuwUbxU35Ze0hlXkL0O4rTtXFEugYcw';
  if ((process.env.REACT_APP_ENV as string) === 'preproduction' || process.env.REACT_APP_ENV === 'development') {
    publicKey = '96555040:testpublickey_Hx07ROrTFcyZBEwQAt63l2SYgt7v9NjJoWUCnwTou2QPS';
  }

  const paymentId = props.payment.id;
  useLayoutEffect(() => {
    async function loadToken() {
      try {
        updateState((draft) => {
          draft.loaded = true;
          draft.loading = true;
        });

        const paymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);
        updateState((draft) => {
          draft.paymentsList = paymentsList;
        });

        const formToken = await loadEntity('bank/extra-payment/pay', paymentId);
        updateState((draft) => {
          draft.formToken = formToken;
        });
      } catch (e) {
        updateState((draft) => {
          draft.promiseError = `${e} (see console for more details)`;
        });
      } finally {
        updateState((draft) => {
          draft.loading = false;
        });
      }
    }

    if (!state.loaded) {
      loadToken().then();
    }
  }, [state.loaded, loadEntity, paymentId, updateState]);
  // loop add loadEntity paymentId

  if (state.loading) {
    return <Spin tip="Extra payment" />;
  }

  if (!state.formToken) {
    return <Fragment />;
  }

  KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
    .then(({ KR }) => KR.setFormConfig({
      /* set the minimal configuration */
      formToken: state.formToken,
      'kr-language': 'fr',
      'kr-post-url-success': '/api/bank/extra/completed',
    }))
    .then(({ KR }) => {
      // record payment error
      KR.onError(async (error) => {
        await axios.post('/bank/extra/refused', error.metadata.answer);
        const paymentsList = await loadEntity('payments-list');

        updateState((draft) => {
          draft.paymentsList = paymentsList;
        });
      });
      return KR.addForm('#myPaymentForm');
    }) /* add a payment form  to myPaymentForm div */
    .then(({ KR, result }) => KR.showForm(result.formId)) /* show the payment form */
    .catch((error) => updateState((draft) => {
      draft.promiseError = `${error} (see console for more details)`;
    }));

  return (
    <div className="form">

      <div className="container">
        <Modal
          visible
          width={1100}
          destroyOnClose={false}
          title={<Locale.Title tkey="payment.resume" />}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
        >

          <div css={css`
                                .table-title{
                                  background-color: whitesmoke;
                                }
                                .table-row-title{
                                  background-color: whitesmoke;
                                }
`}
          >
            <CandidateChat />
            <div style={{ display: 'flex', backgroundColor: 'whitesmoke' }}>
              <div
                style={{ width: '500px', padding: '3em' }}
              >
                <Card title={<Locale.Title tkey="extra-p ayment.history" />}>
                  <PaymentHistory paymentsList={state.paymentsList} />
                </Card>
              </div>
              <div
                style={{ width: '500px', padding: '3em' }}
              >
                <Card
                  style={{ width: 400, margin: 'auto' }}
                  title={<Locale.Title tkey="extra-payment.ask" />}
                >
                  {props.payment?.extraReason}
                  <div
                    id="myPaymentForm"
                    style={{
                      width: '500px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '3em',
                      position: 'relative',
                    }}
                  />
                </Card>
              </div>
            </div>
          </div>
        </Modal>
        <div>{state.promiseError}</div>
      </div>
    </div>
  );
}
