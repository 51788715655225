import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  TableOutlined,
} from '@ant-design/icons';
import {
  IExaminerReview, IProgram, ISpecializedSecretary, UserRoleEnum,
} from '@fstn/ecandidaturev2_api-interfaces';
import Menu from '@inovua/reactdatagrid-community/packages/Menu';
import { Modal } from 'antd';

// @ts-ignore
import React, { useContext } from 'react';
// @ts-ignore
import * as UUID from 'uuid-js';
import * as Locale from '../../common/locale';
import { AxiosContextProvider } from '../../context/axios.context';
import { IhmContextProvider } from '../../context/ihm.context';
import { UserContext } from '../../context/user.context';
import { useEntity } from '../../hooks/use-entity';
import { useLoading } from '../../hooks/use-loading';
import { sNavigate } from '../../utils/safeNavigation';
import { DocumentDownloaderTypeSelector } from '../canDownloadDocuments/documentsDownloader/DocumentDownloaderTypeSelector';

import { ROUTES } from '../user/UserRoutesDef';
import { StrictLoading } from '../../common/indicator/SmartLoading';
import { getEventServiceInstance } from '../../utils/eventService';
import { useSegment } from '../../hooks/use-segment';
import { ExportGroupTypeSelector } from '../canExportXls/ExportGroupTypeSelector';

const { confirm } = Modal;

export function SpecializedSecretaryRowContextMenu({ menuProps, details, selectedCandidateFiles }: any) {
  const { userCtx } = useContext(UserContext);
  const { saveEntity } = useEntity();
  const { doAction, loading } = useLoading();
  const { segment } = useSegment(selectedCandidateFiles);

  const specializedSecretary = userCtx.user as any as ISpecializedSecretary;

  async function createSpecializedSecretaryReviewIfNotExists() {
    // Before to open the candidateFile we need to be sure that there is a specialized review for the current program
    let { specializedSecretaryReview } = details.rowProps.data;
    if (!specializedSecretaryReview) {
      const id = UUID.create(1).toString();
      specializedSecretaryReview = {
        id,
        program: { id: specializedSecretary.program.id } as IProgram,
        candidateFile: { id: details.rowProps.data.id },
        rights: {
          manage: [UserRoleEnum.SPECIALIZED_SECRETARY, UserRoleEnum.SECRETARY],
          read: [UserRoleEnum.SPECIALIZED_SECRETARY, UserRoleEnum.SECRETARY],
          write: [UserRoleEnum.SPECIALIZED_SECRETARY],
          delete: [UserRoleEnum.SPECIALIZED_SECRETARY, UserRoleEnum.SECRETARY],
        },
      } as Partial<IExaminerReview>;
      specializedSecretaryReview = await saveEntity('specialized-secretary-review', specializedSecretaryReview);
    }
    return specializedSecretaryReview;
  }

  const items = [];
  if (selectedCandidateFiles.length === 1) {
    items.push({
      label: <Locale.Button tkey="candidateFile.open" />,
      icon: <FolderOpenOutlined />,
      onClick: async (value) => {
        doAction(async () => {
          await createSpecializedSecretaryReviewIfNotExists();
          getEventServiceInstance().logEvent('open.specialized_secretary.candidate_file', { candidate_file: { id: details.rowProps.data.id } });
          sNavigate(`${ROUTES.SPECIALIZED_SECRETARY}/${details.rowProps.data.id}`);
        });
      },
    });
  }
  // Pour les generations de FS en pdf, on limite a 50 car sinon, c'est trop long à generer 
  if (selectedCandidateFiles.length >= 1 && selectedCandidateFiles.length <= 50) {
    items.push({
      label: <Locale.Button tkey="candidateFile.PDF" />,
      icon: <FilePdfOutlined />,
      disabled: loading,
      onClick: async (value) => {
        await doAction(async () => {
          sNavigate(`${ROUTES.SPECIALIZED_SECRETARY}/pdf`, true, { segmentId: segment.id });
        });
      },
    })
  }
  if (selectedCandidateFiles.length >= 1) {
    items.push({
      label: <Locale.Button tkey="candidateFile.XLS" />,
      icon: <TableOutlined />,
      className: 'openXLS',
      onClick: async () => {
        confirm({
          // @ts-ignore
          title: <Locale.Notification tkey="candidateFile.export.selectType" />,
          icon: <ExclamationCircleOutlined />,
          width: 600,
          okButtonProps: { disabled: true, style: { display: 'none' } },
          content: (
            <div>
              <AxiosContextProvider>
                <IhmContextProvider>
                  <ExportGroupTypeSelector
                    details={details}
                    segmentId={segment.id}
                  />
                </IhmContextProvider>
              </AxiosContextProvider>
            </div>
          ),
        });
      },
    })
  };
  // Pour les generations d'export fichiers en zip, on limite a 15 car sinon, ca sature le serveur en process et surtout memoire 
  if (selectedCandidateFiles.length >= 1 && selectedCandidateFiles.length <= 100) {
    items.push({
      label: <Locale.Button tkey="candidateFile.downloadFiles" />,
      icon: <DownloadOutlined />,
      disabled: loading,
      className: 'openFiles',
      onClick: () => doAction(async () => {
        confirm({
          // @ts-ignore
          title: <Locale.Notification tkey="candidateFile.downloadFiles.selectType" />,
          icon: <ExclamationCircleOutlined />,
          width: 600,
          okButtonProps: { disabled: true, style: { display: 'none' } },
          content: (
            <div>
              <AxiosContextProvider>
                <IhmContextProvider>
                  <DocumentDownloaderTypeSelector
                    details={details}
                    segmentId={segment.id}
                  />
                </IhmContextProvider>
              </AxiosContextProvider>
            </div>
          ),
        });
      }),
    });
  }

  return (
    <StrictLoading loading={loading} context="SpecializedSecretaryRowContextMenu">
      <Menu
        {...menuProps}
        items={items}
      >
      </Menu>
    </StrictLoading>
  );
}
