/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button, Card, Descriptions, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputPasswordFormItem } from '../../../common/form/inputFormItems/InputPasswordFormItem';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { sNavigate } from '../../../utils/safeNavigation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { useResponsiveForm } from "../../../hooks/use-responsive-form.hook";
import { Box } from "rebass";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { labelTrad } from '../../../common/locale/index';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';

export function ForgotPasswordForm(props: { email: string, code: string }) {
    const [form] = useForm();
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const [state, updateState] = useImmer({ captcha: false, valid: false, loading: false, error_captcha: false as boolean });
    const { t } = useSafeTranslation();

    const responsive = useResponsiveForm();

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const onFinish = async (values) => {
        try {
            updateState((draft) => {
                draft.loading = true;
            });
            if (values.password !== values.password2) {
                notification.error({
                    message: <Locale.Title tkey="forgot-password.not.equal" />,
                    description: <Locale.Help tkey="forgot-password.not.equal" />,
                });
                form.resetFields(['password', 'password2']);
                updateState((draft) => {
                    draft.valid = false;
                });
                return;
            }
            await axios.post('/forgot-password', values);
            sNavigate('/login');
        } finally {
            updateState((draft) => {
                draft.loading = false;
            });
        }
    };

    return (
        <Card css={css`max-width: 700px; margin: auto`}>
            <DisabledContextProvider value={{ disabled: false, wrapAuthorized: false }}>
                <Form
                    layout="vertical"
                    form={form}
                    className="login-form"
                    noValidate
                    onFinish={onFinish}
                >
                    <EntityFormContextProvider
                        entity="forgot-password"
                        isFieldTouched={form.isFieldTouched}
                        getFieldValue={form.getFieldValue}
                        getFieldsValue={form.getFieldsValue}
                        onChange={() => undefined}
                        onImmediateChange={() => undefined}
                    >
                        <EntityFormListContextProvider
                            entity="forgot-password"
                            propertyName=""
                            remove={() => {
                            }}
                        >

                            <Descriptions title={<Locale.Title tkey="forgot-password" />}>
                                <Descriptions.Item label=""><Locale.Help tkey="forgot-password" /></Descriptions.Item>
                            </Descriptions>
                            <InputFormItem propertyName="code" initialValue={props.code} hidden />
                            <Box {...responsive.w1}>
                                <InputFormItem
                                    propertyName="email"
                                    rules={[{ type: 'email', required: true }]}
                                    initialValue={props.email}
                                    hidden
                                />
                            </Box>
                            <InputPasswordFormItem
                                propertyName="password"
                                required
                                rules={[{ min: 10, required: true }]}
                                autoComplete="off"
                            />
                            <Box {...responsive.w1}>
                                <InputPasswordFormItem
                                    propertyName="password2"
                                    required
                                    rules={[{ min: 10, required: true }]}
                                    autoComplete="off"
                                />
                            </Box>
                            <Box {...responsive.w1}>
                                <br />
                                <LoadCanvasTemplate />
                                <Locale.Label tkey='saisiecaptcha' />
                                <Input
                                    name="myCaptcha"
                                    id="myCaptcha"
                                />
                                <Button onClick={() => {
                                    // @ts-ignore
                                    const user_captcha_value = document.getElementById('myCaptcha').value;
                                    if (validateCaptcha(user_captcha_value) === true) {
                                        updateState((draft) => {
                                            draft.captcha = true;
                                            draft.error_captcha = false;
                                        });
                                    }
                                    else {
                                        updateState((draft) => {
                                            draft.error_captcha = true;
                                        });
                                    }
                                }
                                }>
                                    {labelTrad("verifCaptcha", t)}
                                </Button>
                                {(state.error_captcha) &&
                                    (// @ts-ignore
                                        <div style={{ color: 'red' }}>
                                            <Locale.Error tkey='bad_captcha'></Locale.Error>
                                        </div>
                                    )}
                                <ButtonsFormItemLayout>
                                    <TextOkButton
                                        type="primary"
                                        tkey="forgot-password"
                                        disabled={state.captcha === false}
                                        loading={state.loading}
                                        htmlType="submit"
                                        className="login-form-button"
                                    />
                                </ButtonsFormItemLayout>
                            </Box>
                        </EntityFormListContextProvider>
                    </EntityFormContextProvider>
                </Form>
            </DisabledContextProvider>
        </Card>
    );
}
