/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { IAcademic, IAcademicsList, IBachelor, ISchool, ISchoolTrack, ISchoolTrackType } from '@fstn/ecandidaturev2_api-interfaces';
import { FormLayout } from 'antd/lib/form/Form';
import React, {
  Fragment, HTMLAttributes, useContext, useRef, useState,
} from 'react';
import { Flex } from 'rebass';
import { useDeepCompareCallback, useDeepCompareEffect } from 'use-deep-compare';
import { useImmer } from 'use-immer';
import UUID from 'uuid-js';
import { DeepPartial } from 'redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import _ from 'lodash';
import { AcademicYearModal } from './AcademicYearModal';
import { EntityFormContainer } from '../../../common/form/entityForm/EntityFormContainer';
import { EntityFormListContainer } from '../../../common/form/entityForm/EntityFormListContainer';
import { FormContentPanelContainer } from '../../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../../common/form/FormStyle';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { SecretaryReviewFormFooterContainer } from '../../../common/form/secretaryReviewForm/SecretaryReviewFormFooterContainer';
import { WithSwitchToReadOnlyOnDisplayForSecretary } from '../../../common/withExtraParameters/WithSwitchToReadOnlyOnDisplayForSecretary';
import { WithInitialLoad } from '../../../common/withExtraParameters/WithInitialLoad';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormContext } from '../../../context/entityForm.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useEntity } from '../../../hooks/use-entity';
import { IsSecretary } from '../../user/conditionnal/IsSecretary';
import { CanSeeValidation } from '../../../common/conditionnal/validation/CanSeeValidation';
import { AcademicFormContainer } from './AcademicFormContainer';
import { AcademicReferentsReviewsListForm } from './AcademicReferentReviewsListForm';
import { DisabledContext, DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProviderForEntityForm } from '../../../common/rights/DisabledContextProviderForEntityForm';
import { FinalReview } from '../../referent/FinalReview';
import { CandidateFileContext, CandidateFileContextProviderType } from '../../canSeeCandidateFile/CandidateFileContext';
import * as Locale from '../../../common/locale';
import { FormListFieldData } from '../../../common/form/type';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import { SchoolAutoCompleteFormItem } from '../../../common/form/inputFormItems/SchoolAutoCompleteFormItem';
import { RightAreaContext, RightAreaContextProviderType } from '../../canSeeCandidateFile/RightAreaContext';

export type AcademicsListProps = HTMLAttributes<HTMLElement>;

/**
 * Genere un bloc d'affichage precisant l'année d'obtention du bac telle que specifiee par le candidat, et les consignes precisant
 * qu'il faut remplir toutes les annees de formation depuis cette date
 * @returns 
 */
function HeaderDetails() {
  // Recuperation des infos du bac liees au dossier du candidat
  const { loadEntity } = useEntity<IBachelor>();
  const [state, updateSate] = useImmer({
    loaded: false,
    entityValue: undefined as IBachelor,
  });
  async function load() {
    updateSate((draft) => {
      draft.loaded = true;
    });
    const res = await loadEntity('bachelor');
    updateSate((draft) => {
      draft.entityValue = res;
    });
  }

  useDeepCompareEffect(() => {
    if (!state.loaded) {
      load().then();
    }
  }, [state.loaded]);

  if (!state.loaded || !state.entityValue) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <span css={css`font-size: 1.2em; font-weight: bold; color: #DC143C`}><Locale.Content tkey='bachelorObtentionDate' /> {state.entityValue.obtentionYear}</span>
      <br />
      <Locale.Content tkey='fillRecommandation' />
    </Fragment>
  )
}

const { confirm } = Modal;
const entity = 'academics-list';
export function AcademicsListEntityFormContainer(props: { yearUpdated: number }) {
  // console.log('Redraw AcademicsListEntityFormContainer');
  const { deleteEntity } = useEntity();
  const { putEntity } = useEntity<any>();
  const hiddenYears = useScopedSelector<any[], number[]>('AcademicsListEntityFormContainer_year', EntityFormContext, (c) => c[0]?.entityValue?.academics?.map((a) => a.year));
  const academics = useScopedSelector<any[], any>('AcademicsListForm', EntityFormContext, (c) => c[0]?.entityValue?.academics);
  const lastDegreeName = useScopedSelector<any[], ISchoolTrack>('AcademicsListForm_lastDegreeName', EntityFormContext, (c) => c[0]?.entityValue?.lastDegreeName);
  const lastDegreeSchoolTrackType = useScopedSelector<any[], ISchoolTrackType>('AcademicsListForm_lastDegreeSchoolTrackType', EntityFormContext, (c) => c[0]?.entityValue?.lastDegreeSchoolTrackType);
  const lastSchoolName = useScopedSelector<any[], ISchool>('AcademicsListForm_lastSchoolName', EntityFormContext, (c) => c[0]?.entityValue?.lastSchoolName);
  const disabled = useScopedSelector('AcademicFormContainer_disabled', DisabledContext, (c) => c?.disabled);

  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  const candidateFileId = candidateFileContext?.selectedCandidateFile?.id;
  const { canEditValidation } = useContext(UserContext);

  const [state, updateState] = useImmer({
    schoolTracks: [],
    showAddAcademic: false,
    add: undefined,
    yearAdded: undefined,  // Annee du cursus que l'on vient d'ajouter (pour pouvoir ouvrir l'onglet correspondant en refresh)
    firstRender: true  // Permet de savoir si on est au premier render et donc si on doit tenir compte de l'année passée en param dans la prop (qui helas reste définie en permanence)
  });

  async function onAddAcademic(academic: any) {
    updateState((draft) => {
      draft.showAddAcademic = false;
      draft.yearAdded = academic.year;
      draft.firstRender = false;  // On indique qu'on n'est plus au premier rendu (sinon, l'appli tient compte de la props pour l'ouverture de l'onglet)
    });
    state.add(academic);
  }

  async function onCancelAddAcademic() {
    updateState((draft) => {
      draft.showAddAcademic = false;
    });
  }

  function onChangeCollapseState(item) {
    updateState((draft) => {
      draft.yearAdded = academics[item]?.year;
      draft.firstRender = false;  // On indique qu'on n'est plus au premier rendu (sinon, l'appli tient compte de la props pour l'ouverture de l'onglet)
    });
  }

  function getAcademicForField(field: FormListFieldData) {
    return academics?.[field.fieldKey];
  }

  let academicToExpand; // Contient l'annee de l'onglet des cursus a ouvrir lors des refresh
  /* Dans le cas du premier rendu (arrivée dans la page ou refresh general suite à une modification de la periode qui doit rafraichir les noms de matiere)
     on ouvre (s'il est defini) le cursus liée à l'année passée en props (cas du refresh general suite à un changement de periode)
     Sinon, on prend l'année qui a été ajoutée (ou ouverte via l'ouverture d'onglet via onChangeCollapseState)
  */
  if (state.firstRender) {
    academicToExpand = props.yearUpdated || state.yearAdded;
  }
  else {
    academicToExpand = state.yearAdded;
  }

  return (
    <Flex flexDirection="column">
      <AcademicYearModal
        onCancel={onCancelAddAcademic}
        onAddAcademic={onAddAcademic}
        showAcademicYear={state.showAddAcademic}
        hiddenYears={hiddenYears}
      />
      <FormContentPanelContainer partName={entity}>
        <EntityFormListContainer
          propertyName="academics"
          entity="academics"
          ignoreMaxLimit={true}  // Permet d'indiquer ici qu'on n'interdit pas de saisir plus de cursus que specifie dans MAX_ACADEMICS car on peut saisir des années supplementaires
          headerDetails={HeaderDetails()}
          sort={(acc1, acc2) => getAcademicForField(acc2)?.year - getAcademicForField(acc1)?.year}
          hackRefresh={state.yearAdded ? [Math.random()] : [1]  // Ruse qui permet, en affectant une nouvelle valeur a cette prop, d'indiquer au composant de forcer un refresh de la liste
            // necessaire ici pour reorganiser la liste completement (tri) après chaque ajout d'un nouveau cursus
          }
          addDecorator={async (add) => {
            updateState((draft) => {
              draft.showAddAcademic = true;
              draft.add = add;
            });
          }}
          removeDecorator={async (remove, value: IAcademic) => {
            if (value.degreeDocument) {
              await deleteEntity('document', value.degreeDocument);
            }
            if ((value as any).bulletinOnLastYearDocument) {
              await deleteEntity('document', (value as any).bulletinOnLastYearDocument);
            }
            if (value.notation1Document) {
              await deleteEntity('document', value.notation1Document);
            }
            if (value.notation2Document) {
              await deleteEntity('document', value.notation2Document);
            }
            remove();
          }}
        >
          <AcademicFormContainer academicToExpand={academicToExpand} onCollapseChange={onChangeCollapseState} />
        </EntityFormListContainer>

        <CanSeeValidation>

          <DisabledContextProvider value={{
            disabled: !canEditValidation(),
            wrapAuthorized: true,
            context: 'AcademicsListForm',
          }}
          >
            <Locale.Header tkey='reservedSpace' />
            <YesNoFormItem propertyName="unjustifiedAbsences" />
            <YesNoFormItem propertyName="problemBehaviorsMentioned" />
            <FinalReview
              propertyName="finalReview"
              onChange={async () => {
                await putEntity('comments/onSecretaryUpdateAcademicReferentFinalReview', { id: candidateFileId }, { candidateFileId });
              }}
            />
            <section id="documents">
              <ExternalApprovalDocument documentNb={1} />
            </section>
            <section id="documents">
              <ExternalApprovalDocument documentNb={2} />
            </section>
            <section id="documents">
              <ExternalApprovalDocument documentNb={3} />
            </section>
          </DisabledContextProvider>
        </CanSeeValidation>
        <FormContentPanelContainer partName="common">
          <Fragment>
            <DisabledContextProvider value={{ disabled: disabled || !!lastDegreeName, wrapAuthorized: true }}>
              <ColdDataAutoCompleteFormItem
                api="school-track-types"
                allowClear
                propertyName='lastDegreeSchoolTrackType'
              />
            </DisabledContextProvider>
            {!!lastDegreeSchoolTrackType
              && (
                <DisabledContextProvider value={{ disabled: disabled || !!lastSchoolName, wrapAuthorized: true }}>
                  <ColdDataAutoCompleteFormItem
                    api="school-tracks"
                    allowClear
                    propertyName='lastDegreeName'
                    dependenciesValues={[lastDegreeSchoolTrackType]}
                    dependencies={['lastDegreeSchoolTrackType']}
                    filter={(s: ISchoolTrack) => {
                      return lastDegreeSchoolTrackType.id === s.type?.id;
                    }}
                  />
                </DisabledContextProvider>
              )}
            {!!lastDegreeName && !!lastDegreeSchoolTrackType
              && (
                <DisabledContextProvider value={{ disabled: disabled, wrapAuthorized: true }}>
                  <SchoolAutoCompleteFormItem
                    allowClear
                    schoolTrack={lastDegreeName}
                    dependenciesValues={[lastDegreeName]}
                    dependencies={['lastDegreeName']}
                    propertyName='lastSchoolName'
                  />
                </DisabledContextProvider>
              )}
          </Fragment>
        </FormContentPanelContainer>
      </FormContentPanelContainer>

      <section id="documents">
        <AcademicsPostStudiesDocument />
      </section>
      <section id="reviews">
        <AcademicReferentsReviewsListForm />
      </section>
    </Flex>
  );
}

export const AcademicsPostStudiesDocument: React.FunctionComponent<AcademicsListProps> = () => {
  // console.log('Redraw AcademicsPostStudiesDocument');

  const entityId = useScopedSelector<any[], string>('AcademicsPostStudiesDocument', EntityFormContext, (c) => c[0]?.entityValue?.id);
  const addPostStudiesApprovalDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: 'postStudiesApprovalDocument',
    type: 'postStudiesApprovalDocument',
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  return (
    <Fragment>
      <FormContentPanelContainer partName="postStudiesApprovalDocument">
        <UploadFormItemContainer
          propertyName="postStudiesApprovalDocument"
          factory={addPostStudiesApprovalDocument}
          accept={'image/*,.pdf'}
        />
      </FormContentPanelContainer>
    </Fragment>
  );
};

export function ExternalApprovalDocument(props: { documentNb: number }) {
  const entityId = useScopedSelector<any[], string>(`AcademicsPostStudiesDocument`, EntityFormContext, (c) => c[0]?.entityValue?.id);
  const propertyName = `externalApprovalDocument${props.documentNb}`;
  const addExternalApprovalDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: propertyName,
    type: propertyName,
    parentType: entity,
    parentId: entityId,
  }), [entityId, entity]);
  return (
    <Fragment>
      <FormContentPanelContainer partName={propertyName}>
        <UploadFormItemContainer
          propertyName={propertyName}
          factory={addExternalApprovalDocument}
          accept={'image/*,.pdf'}
        />
      </FormContentPanelContainer>
    </Fragment>
  );
};

export function AcademicsListForm(props: { layout?: FormLayout }) {
  const [forceReloadHack, setForceReloadHack] = useState(0);
  const previousData = useRef(null);
  const { canEditValidation } = useContext<UserContextType>(UserContext);
  const { deleteEntity } = useEntity();
  const rightCtx = useContext<RightAreaContextProviderType>(RightAreaContext);

  const [state, updateState] = useImmer({
    yearUpdated: undefined  // Permet de savoir si on a demande un refresh en precisant l'année concernée
  });

  const onBeforeValidate = async (values: IAcademicsList | any, differences: Partial<IAcademicsList>, setFieldsValue, oldData: DeepPartial<IAcademicsList>) => {
    if (differences?.academics?.length > 0) {
      // On regarde ici si des criteres d'obligation du releve de note BTS ont été supprimés, auquel cas on supprime le degreeDocument qui n'a plus lieu d'etre
      differences?.academics?.forEach(async (academicUpdate, i) => {
        // On se positionne sur le cursus modifie (s'il existe)
        if (academicUpdate) {
          if ((!!!(values.academics[i]?.degreeObtained === true) || values.academics[i]?.period !== 'seconde année') && values.academics[i]?.degreeDocument?.id) {
            await deleteEntity('document', values.academics[i]?.degreeDocument);
            _.set(differences, `academics[${i}].degreeDocument`, null);
            rightCtx?.forceUpdate?.();
            setForceReloadHack(Math.random());
          }
          // Reset des champs conditionnes : cancelReason et schoolDetail qui doivent etre vidés si les conditions de leur remplissage ne sont pas remplies
          // cancelCourse doit etre à true pour cancelReason, et school.name = "Autre établissement" pour schoolDetail
          if (values.academics[i]?.cancelCourse !== true) {
            _.set(differences, `academics[${i}].cancelReason`, null);
          }
          if (!!!values.academics[i]?.school?.name.includes('Autre établissement')) {
            _.set(differences, `academics[${i}].schoolDetail`, null);
          }
          // NOTE : le setFieldValues ne fonctionne pas sur les details d'un cursus (il ne fonctionne que sur les champs principaux de la liste)
          // donc on ne peut pas faire le reset dans l'IHM !
        }
      })
      differences?.academics?.forEach((academicUpdate, i) => {
        const previous = previousData.current || oldData;
        const academicPreviousValue = _.get(previous, `academics[${i}]`);
        if (canEditValidation()
          && academicUpdate
          && 'period' in academicUpdate
          && academicUpdate.period
          // eslint-disable-next-line eqeqeq
          && academicUpdate.period != _.get(academicPreviousValue, 'period')) {
          _.set(differences, `academics[${i}].s1GenAverage`, null);
          _.set(differences, `academics[${i}].s2GenAverage`, null);
          _.set(differences, `academics[${i}].s2m1Average`, null);
          _.set(differences, `academics[${i}].s2m2Average`, null);
          _.set(differences, `academics[${i}].s1m1Average`, null);
          _.set(differences, `academics[${i}].s1m2Average`, null);
          _.set(differences, `academics[${i}].s1Rank`, null);
          _.set(differences, `academics[${i}].s2Rank`, null);
          confirm({
            title: <Locale.Notification tkey="secretaryReview.changingNote.subjectName" />,
            icon: <ExclamationCircleOutlined />,
            content: <Locale.Help tkey="secretaryReview.changingNote.subjectName" />,
            async onOk() {
              setTimeout(() => {
                const currId = _.get(previous, `academics[${i}].id`);
                updateState((draft) => {
                  draft.yearUpdated = _.get(previous, `academics[${i}].year`)
                });
                window.location.href = `${window.location.href}#${currId}`;
                localStorage.setItem('keepEdit', '1');
                setForceReloadHack(Math.random());
                setTimeout(() => {
                  window.location.href = `${window.location.href}#${currId}`;
                }, 1000);
              }, 500);
            },
          });
        }
      });
    }
    previousData.current = (values);
    return differences;
  };
  return (
    <FormStyle>
      <WithInitialLoad entity={entity} forceReloadWhenThisValueChange={`${forceReloadHack}`}>
        <WithSwitchToReadOnlyOnDisplayForSecretary>
          <EntityFormContainer listeners={{ onBeforeValidate }} layout={props.layout}>
            <DisabledContextProviderForEntityForm>
              <AcademicsListEntityFormContainer yearUpdated={state.yearUpdated} />
              <IsSecretary>
                <SecretaryReviewFormFooterContainer propertyName="secretaryReview" />
              </IsSecretary>
            </DisabledContextProviderForEntityForm>
          </EntityFormContainer>
        </WithSwitchToReadOnlyOnDisplayForSecretary>
      </WithInitialLoad>
    </FormStyle>
  );
}
