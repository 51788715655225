/** @jsx jsx */
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  CandidateFileStatusEnum,
  ICandidateFileStatus,
  IDocument,
  IUserRole,
  UserRoleEnum,
} from '@fstn/ecandidaturev2_api-interfaces';
import { Badge, Menu } from 'antd';
import Button from 'antd-button-color';
import _ from 'lodash';
import * as React from 'react';
import { Fragment } from 'react';
import * as Locale from '../../../common/locale';
import { EntityIcon } from '../../../assets/icons/left-menu.icons';
import { kebabCase } from '../../../utils/strings';

function getIDocuments(documentsList: IDocument[], documentParents: string[]) {
  return documentsList?.filter((d: IDocument) => documentParents.includes(d.parentType));
}

// @ts-ignore
const Style = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  grid-area: right-menu;
  
  .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .ant-badge-count{
    background-color: #29B6F6 !important;
  }
  .ant-menu-submenu-arrow {
    display: none;
  }

  .ant-menu-horizontal {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .ant-menu-light {
    background-color: rgb(250, 250, 250);
    width:100%;
  }

  .ant-menu-item-selected {
    background-color: #29B6F6 !important;
  }

  .ant-menu > .ant-menu-item-divider{
    height: 20px
  }`;

const subMenuDocumentStyle = css`
  background-color: #fff !important;
  .ant-menu-submenu-title {
    display: flex;
    gap: 5px;
    align-items: center;
  }`;

const subMenuDocumentStyle2 = css`
  min-width: 180px !important;`;

const subMenuFormStyle = css`
  background-color: #7B7D7D !important;


  .ant-menu-submenu-title {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;

export function RightMenu(props: {
  displayOnRight: any,
  hideOnRight: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  disabled: boolean,
  documentsList: IDocument[],
  userRole: IUserRole,
  candidateFileStatus: ICandidateFileStatus
}) {
  const menuHeader = {
    programsList: ['programLines'],
    profile: ['profile'],
    bachelor: ['bachelor'],
    academics: ['academics', 'academics-list', 'a-r-review'],
    language: ['language'],
    stayAbroad: ['stayAbroad'],
    companyExperiences: ['companyExperiences', 'companyReferentReviews'],
    additionalDocument: ['additionalDocument'],
    paymentsList: ['payments-list'],
  };
  return (
    <Style>
      <Menu onClick={props.displayOnRight} mode="vertical">
        <Button
            /* ok */ disabled={props.disabled}
          onClick={props.hideOnRight}
        >
          <MenuUnfoldOutlined size={2} />
        </Button>
        {Object.keys(menuHeader).map((header: string) => (
          <Menu.SubMenu
            css={subMenuDocumentStyle}
            key={`document_${header}`}
            title={(
              <Fragment>
                <Badge count={getIDocuments(props.documentsList, menuHeader[header])?.length}>
                  <EntityIcon
                    entity={kebabCase(header)}
                  />
                </Badge>
              </Fragment>
            )}
          >
            {_.sortBy(getIDocuments(props.documentsList, menuHeader[header]) || [], (d) => `${d.parentType}_${d.name}`)
              .map((d: IDocument) => (
                <Menu.Item css={subMenuDocumentStyle2} key={`document_${d.id}`}>
                  <Locale.Button tkey={`${d.parentType}_${d.name}`} />
                </Menu.Item>
              ))}
          </Menu.SubMenu>
        ))}
        <Menu.Divider />
        {props.userRole?.name === UserRoleEnum.SECRETARY
          && props.candidateFileStatus.id !== CandidateFileStatusEnum.NEW && (
            <Menu.Item
              css={subMenuFormStyle}
              key="form_commentsBtwSecretaries"
            >
              <EntityIcon
                entity="commentsBtwSecretaries"
              />
            </Menu.Item>
          )}
        {props.candidateFileStatus?.id !== CandidateFileStatusEnum.NEW
          && (
            <Menu.Item
              css={subMenuFormStyle}
              key="form_commentsBtwAllTypesOfSecretaries"
            >
              <EntityIcon
                entity="commentsBtwAllTypesOfSecretaries"
              />
            </Menu.Item>
          )}
        {(props.userRole?.name === UserRoleEnum.SPECIALIZED_SECRETARY
          || props.userRole?.name === UserRoleEnum.SECRETARY
          || props.userRole?.name === UserRoleEnum.EXAMINER)
          && props.candidateFileStatus?.id !== CandidateFileStatusEnum.NEW && (
            <Menu.Item
              css={subMenuFormStyle}
              key="form_missingFields"
            >
              <EntityIcon
                entity="missingFields"
              />
            </Menu.Item>
          )}
        {props.userRole?.name === UserRoleEnum.SECRETARY && (
          <Menu.Item
            css={subMenuFormStyle}
            key="form_commentsBtwSecretaryAndCandidate"
          >
            <EntityIcon
              entity="commentsBtwSecretaryAndCandidate"
            />
          </Menu.Item>
        )}
        {(props.userRole?.name === UserRoleEnum.SPECIALIZED_SECRETARY
          || props.userRole?.name === UserRoleEnum.EXAMINER)
          && props.candidateFileStatus?.id !== CandidateFileStatusEnum.NEW && (
            <Menu.Item
              css={subMenuFormStyle}
              key="form_specializedSecretary"
            >
              <EntityIcon
                entity="specializedSecretary"
              />
            </Menu.Item>
          )}

      </Menu>
    </Style>
  );
}
