/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  DocumentTypeEnum, IDocument, ISchool, ISchoolTrack, ISchoolTrackType,
} from '@fstn/ecandidaturev2_api-interfaces';
import { Collapse } from 'antd';
import { Fragment } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';
import { ColdDataAutoCompleteFormItem } from '../../../common/form/inputFormItems/ColdDataAutoCompleteFormItem';
import { FixSelectFormItem } from '../../../common/form/inputFormItems/FixSelectFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { YesNoFormItem } from '../../../common/form/inputFormItems/YesNoFormItems';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { CanSeeValidation } from '../../../common/conditionnal/validation/CanSeeValidation';
import { ValidationNotesForm } from './ValidationNotesForm';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { StrictLoading } from '../../../common/indicator/SmartLoading';
import { DisabledContext, DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { DisabledContextProviderAlwaysDisabled } from '../../../common/rights/DisabledContextProviderAlwaysDisabled';
import { InputTextAreaFormItem } from '../../../common/form/inputFormItems/InputTextAreaFormItem';
import { SchoolAutoCompleteFormItem } from '../../../common/form/inputFormItems/SchoolAutoCompleteFormItem';
import { ColdDataSelectorFormItem } from '../../../common/form/inputFormItems/ColdDataSelectorFormItem';

export function AcademicFormContainer(props: { academicToExpand?: any, onCollapseChange?: any }) {
  // console.log('Redraw AcademicFormContainer');
  const { t } = useSafeTranslation();

  const getFieldValue = useScopedSelector<any[], any>('AcademicFormContainer', EntityFormContext, (c) => c[0]?.getFieldValue);
  const formListEntity = useScopedSelector<any[], string>('AcademicFormContainer', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('AcademicFormContainer', EntityFormListContext, (c) => c[0].field);
  const year = useScopedSelector<any[], number>('AcademicFormContainer', EntityFormListContext, (c) => c[0]?.entityValue?.year);
  const degreeObtained = useScopedSelector<any[], boolean>('AcademicFormContainer_degreeObtained', EntityFormListContext, (c) => c[0]?.entityValue?.degreeObtained);
  const schoolTrack = useScopedSelector<any[], ISchoolTrack>('AcademicFormContainer_schoolTrack', EntityFormListContext, (c) => c[0]?.entityValue?.schoolTrack);
  const schoolTrackType = useScopedSelector<any[], ISchoolTrackType>('AcademicFormContainer_schoolTrackType', EntityFormListContext, (c) => c[0]?.entityValue?.schoolTrackType);
  const period = useScopedSelector<any[], string>('AcademicFormContainer_period', EntityFormListContext, (c) => c[0]?.entityValue?.period);
  const school = useScopedSelector<any[], ISchool>('AcademicFormContainer_school', EntityFormListContext, (c) => c[0]?.entityValue?.school);
  const formListEntityId = useScopedSelector<any[], string>('AcademicFormContainer', EntityFormListContext, (c) => c[0]?.entityValue?.id);
  const disabled = useScopedSelector('AcademicFormContainer_disabled', DisabledContext, (c) => c?.disabled);

  function getAcademicTitle() {
    const year1 = year - 1;
    return `${t('academic.year.title')} ${year1 || ''}-${year || ''} - ${schoolTrack?.id || ''}  - ${period || ''} - ${school?.name || ''}`;
  }

  const addDegreeDocument = useDeepCompareCallback((): IDocument => ({
    id: UUID.create(1).toString(),
    name: `${year}_${DocumentTypeEnum.DEGREE}`,
    type: DocumentTypeEnum.DEGREE,
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntity, formListEntityId]);
  const addNotation1Document = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: `${year}_${DocumentTypeEnum.NOTATION1}`,
    type: DocumentTypeEnum.NOTATION1,
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntity, formListEntityId]);
  const addNotation2Document = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name: `${year}_${DocumentTypeEnum.NOTATION2}`,
    type: DocumentTypeEnum.NOTATION2,
    parentType: formListEntity,
    parentId: formListEntityId,
  }), [formListEntity, formListEntityId]);

  const academicTitle = getAcademicTitle();
  const currentYear = new Date().getFullYear();
  return (
    <StrictLoading loading={!formListEntityId} context="AcademicFormContainer">
      <Collapse
        defaultActiveKey={`${year === props.academicToExpand ? field.name : ''}` // on deplie l'onglet du cursus qui doit l'être (soit juste créé, soit modifié avec refresh général)
        }
        onChange={(e) => {
          props.onCollapseChange(e[1])  // On indique a l'appelant (qui mettra a jour son state) l'onglet (annee) qui vient d'être depliée
        }
        }
        ghost
      >
        <Collapse.Panel header={academicTitle} key={`${field.name}`}>
          <DisabledContextProviderAlwaysDisabled>
            <FixSelectFormItem
              propertyName={['academics', field.name, 'year']}
              options={[
                { value: currentYear, label: `${currentYear - 1}-${currentYear}` },
                {
                  value: currentYear - 1,
                  label: `${currentYear - 2}-${currentYear - 1}`,
                },
                {
                  value: currentYear - 2,
                  label: `${currentYear - 3}-${currentYear - 2}`,
                },
                {
                  value: currentYear - 3,
                  label: `${currentYear - 4}-${currentYear - 3}`,
                },
                {
                  value: currentYear - 4,
                  label: `${currentYear - 5}-${currentYear - 4}`,
                },
                {
                  value: currentYear - 5,
                  label: `${currentYear - 6}-${currentYear - 5}`,
                },
              ]}
            />
          </DisabledContextProviderAlwaysDisabled>
          <DisabledContextProviderAlwaysDisabled>
            <YesNoFormItem
              // disabled
              propertyName={['academics', field.name, 'student']}
            />
          </DisabledContextProviderAlwaysDisabled>
          { /* not change this to entityValue, it doesn't work */}
          {getFieldValue(['academics', field.name, 'student'])
            && (
              <Fragment>

                <DisabledContextProvider value={{ disabled: disabled || !!schoolTrack, wrapAuthorized: true }}>
                  <ColdDataAutoCompleteFormItem
                    api="school-track-types"
                    allowClear
                    propertyName={['academics', field.name, 'schoolTrackType']}
                  />
                </DisabledContextProvider>
                {schoolTrackType
                  && (
                    <DisabledContextProvider value={{ disabled: disabled || !!school, wrapAuthorized: true }}>
                      <ColdDataAutoCompleteFormItem
                        api="school-tracks"
                        allowClear
                        propertyName={['academics', field.name, 'schoolTrack']}
                        dependenciesValues={[schoolTrackType]}
                        dependencies={['schoolTrackType']}
                        filter={(s: ISchoolTrack) => {
                          return schoolTrackType.id === s.type?.id;
                        }}
                      />
                    </DisabledContextProvider>
                  )}
                {schoolTrack && schoolTrackType
                  && (
                    <DisabledContextProvider value={{ disabled: disabled || !!period, wrapAuthorized: true }}>
                      <SchoolAutoCompleteFormItem
                        allowClear
                        schoolTrack={schoolTrack}
                        dependenciesValues={[schoolTrack]}
                        dependencies={[['academics', field.name, 'schoolTrack']]}
                        propertyName={['academics', field.name, 'school']}
                      />
                    </DisabledContextProvider>
                  )}
                {/* Si le candidat a choisi 'Autre établissement', il doit renseigner l'établissement dans le champ "schoolDetail"
                    Note : il faut un "includes" car selon si on arrive dans la page, ou si on change un element de la combo, le name ne contient pas la meme chose
                    juste le nom a l'arrive dans la page, et le nom formate avec ville, etc. quand changement de valeur dans la combo */}
                {school?.name.includes('Autre établissement')
                  && (
                    <InputTextAreaFormItem
                      dependencies={['academics', field.name, 'school']}
                      dependenciesValues={[school]}
                      propertyName={['academics', field.name, 'schoolDetail']} />
                  )}

                {school && schoolTrack && schoolTrackType && schoolTrack?.periods
                  && (
                    <FixSelectFormItem
                      dependencies={[['academics', field.name, 'school']]}
                      allowClear
                      dependenciesValues={[schoolTrack?.periods]}
                      options={schoolTrack?.periods?.map((p) => ({ value: p, label: p }))}
                      propertyName={['academics', field.name, 'period']}
                    />
                  )}

                {school && schoolTrack && period
                  && (
                    <ColdDataSelectorFormItem
                      api="program-types"
                      allowClear
                      dependenciesValues={[school, period, schoolTrack]}
                      dependencies={[['academics', field.name, 'school'], ['academics', field.name, 'period'], ['academics', field.name, 'schoolTrack']]}
                      propertyName={['academics', field.name, 'programType']}
                    />
                  )}
                {year !== new Date().getFullYear()
                  && <YesNoFormItem propertyName={['academics', field.name, 'degreeObtained']} />}
                <YesNoFormItem propertyName={['academics', field.name, 'cancelCourse']} />
                {/* Si le candidat a precise qu'il a abandonne le cursus en cours d'annee, on lui demande les details de cet abandon */}
                {getFieldValue(['academics', field.name, 'cancelCourse'])
                  && (
                    <InputTextAreaFormItem propertyName={['academics', field.name, 'cancelReason']} />
                  )}

                {(`${schoolTrack?.type?.id}` === 'BTS'
                  && period === 'seconde année'
                  && degreeObtained) && (
                    <UploadFormItemContainer
                      propertyName={['academics', field.name, 'degreeDocument']}
                      accept={'image/*,.pdf'}
                      factory={addDegreeDocument}
                    />
                  )}
                <UploadFormItemContainer
                  propertyName={['academics', field.name, 'notation1Document']}
                  accept={'image/*,.pdf'}
                  factory={addNotation1Document}
                />

                {year !== new Date().getFullYear()
                  && (
                    <UploadFormItemContainer
                      propertyName={['academics', field.name, 'notation2Document']}
                      accept={'image/*,.pdf'}
                      factory={addNotation2Document}
                    />
                  )}

                {period && (
                  <CanSeeValidation>
                    <ValidationNotesForm field={field} />
                  </CanSeeValidation>
                )}
              </Fragment>
            )}
          { /* s'il ne s'agit pas d'une annee effectuee en tant qu'etudiant, on demande l'activite non etudiante effectuee durant cette annee */
          }
          {!getFieldValue(['academics', field.name, 'student'])
            && (
              <Fragment>
                <ColdDataSelectorFormItem
                  api="nostudy-activities"
                  allowClear
                  propertyName={['academics', field.name, 'nostudyActivity']}
                />
              </Fragment>
            )}
          <InputTextAreaFormItem propertyName={['academics', field.name, 'comments']} />
        </Collapse.Panel>
      </Collapse>
    </StrictLoading>
  );
}
