import { MaskedInput } from 'antd-mask-input';
import { InputProps } from 'antd/lib/input';
import React from 'react';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';
import { Debounced } from '../../withExtraParameters/Debounced';
import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { WithOnChangeValueChange } from '../../withExtraParameters/WithOnChangeValueChange';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

function InputMaskedFormItemContent(props: { propertyName: string | any[] | number } & MaskedInputProps) {
    const disabled = useScopedSelector('InputPasswordFormItemContent', DisabledContext, (c) => c?.disabled);
    return (
      <MaskedInput
        data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
        allowClear={props.allowClear}
        autoComplete={props.autoComplete}
        readOnly={disabled}
        value={props.value}
        onChange={props.onChange}
        mask={props.mask}
        type={props.type}
        onPressEnter={props.onPressEnter}
        addonBefore={props.addonBefore}
        addonAfter={props.addonAfter}
        prefix={props.prefix}
        suffix={props.suffix}
        bordered={props.bordered}
        onBlur={props.onBlur}
        onClick={props.onClick}
        onFocus={props.onFocus}
      />
);
}

/**
 * EntityFormItemContainer for  input
 * @param props
 * @constructor
 */
export function InputMaskedFormItem(props: ItemProps & Omit<InputProps, 'disabled'> & { maskFormat: string }) {
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <Debounced>
          <WithOnChangeValueChange valueMapper={(e) => {
                  const { value } = e.target;
                  if (!value) {
                      return undefined;
                  }
                  return value;
              }}
          >
            <InputMaskedFormItemContent
              propertyName={props.propertyName}
              allowClear={props.allowClear}
              autoComplete={props.autoComplete}
              mask={props.maskFormat}
            />
          </WithOnChangeValueChange>
        </Debounced>
      </EntityFormItemContainer>
        );
}
