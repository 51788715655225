/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button, Card, Descriptions, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useContext, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../../common/button/TextOkButton';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { InputNumberFormItem } from '../../../common/form/inputFormItems/InputNumberFormItem';
import * as Locale from '../../../common/locale';
import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContextProvider } from '../../../context/entityForm.context';
import { EntityFormListContextProvider } from '../../../context/entityFormList.context';
import { ROUTES } from '../../user/UserRoutesDef';
import { sNavigate } from '../../../utils/safeNavigation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { Box } from "rebass";
import { useResponsiveForm } from "../../../hooks/use-responsive-form.hook";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import { labelTrad } from '../../../common/locale/index';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';

export function ForgotPasswordCodeForm(props: { email: string }) {
    const [form] = useForm();
    const [state, updateState] = useImmer({ captcha: false, valid: false, loading: false, error_captcha: false as boolean });
    const { axios } = useContext<AxiosContextType>(AxiosContext);
    const { t } = useSafeTranslation();
    const responsive = useResponsiveForm();

    useEffect(() => {
        loadCaptchaEnginge(6);
    }, []);

    const onFinish = async (values) => {
        try {
            updateState((draft) => {
                draft.loading = true;
            });
            try {
                await axios.post('/forgot-password-code', values);
                sNavigate(`${ROUTES.PUBLIC_BASE}/forgot-password/${values.email}/${values.code}`);
            } catch (e) {
                if (e?.response?.status === 401) {
                    notification.error({
                        message: <Locale.Title tkey="forgot-password-code.invalid" />,
                        description: <Locale.Help tkey="forgot-password-code.invalid" />,
                    });
                } else {
                    notification.warn({
                        message: <Locale.Title tkey="server.error" />,
                        description: <Locale.Help tkey="server.error" />,
                    });
                }
                throw e;
            }
        } finally {
            updateState((draft) => {
                draft.loading = false;
            });
        }
    };

    return (
        <Card css={css`max-width: 700px; margin: auto`}>
            <DisabledContextProvider value={{ disabled: false, wrapAuthorized: false }}>
                <Form
                    layout="vertical"
                    form={form}
                    className="login-form"
                    noValidate
                    onFinish={onFinish}
                >
                    <EntityFormContextProvider
                        entity="forgot-password"
                        isFieldTouched={form.isFieldTouched}
                        getFieldValue={form.getFieldValue}
                        getFieldsValue={form.getFieldsValue}
                        onChange={() => undefined}
                        onImmediateChange={() => undefined}
                    >
                        <EntityFormListContextProvider
                            entity="forgot-password"
                            propertyName=""
                            remove={() => {
                            }}
                        >

                            <Descriptions title={<Locale.Title tkey="forgot-password.email" />}>
                                <Descriptions.Item label="">
                                    <Locale.Help
                                        tkey="forgot-password.email"
                                    />
                                </Descriptions.Item>
                            </Descriptions>

                            <Box {...responsive.w1}>
                                <DisabledContextProvider value={{ disabled: true, wrapAuthorized: true }}>
                                    <InputFormItem propertyName="email" initialValue={props.email} />
                                </DisabledContextProvider>
                            </Box>
                            <Box {...responsive.w1}>
                                <InputNumberFormItem propertyName="code" required rules={[{ required: true }]} />
                            </Box>
                            <Box {...responsive.w1}>
                                <br />
                                <LoadCanvasTemplate />
                                <Locale.Label tkey='saisiecaptcha' />
                                <Input
                                    name="myCaptcha"
                                    id="myCaptcha"
                                />
                                <Button onClick={() => {
                                    // @ts-ignore
                                    const user_captcha_value = document.getElementById('myCaptcha').value;
                                    if (validateCaptcha(user_captcha_value) === true) {
                                        updateState((draft) => {
                                            draft.captcha = true;
                                            draft.error_captcha = false;
                                        });
                                    }
                                    else {
                                        updateState((draft) => {
                                            draft.error_captcha = true;
                                        });
                                    }
                                }
                                }>
                                    {labelTrad("verifCaptcha", t)}
                                </Button>
                                {(state.error_captcha) &&
                                    (// @ts-ignore
                                        <div style={{ color: 'red' }}>
                                            <Locale.Error tkey='bad_captcha'></Locale.Error>
                                        </div>
                                    )}
                                <ButtonsFormItemLayout>
                                    <TextOkButton
                                        tkey="code"
                                        type="primary"
                                        disabled={state.captcha === false}
                                        loading={state.loading}
                                        htmlType="submit"
                                        className="login-form-button"
                                    />
                                </ButtonsFormItemLayout>
                            </Box>
                        </EntityFormListContextProvider>
                    </EntityFormContextProvider>
                </Form>
            </DisabledContextProvider>
        </Card>
    );
}
