/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { DEFAULT } from '@fstn/ecandidaturev2_api-interfaces';
import { useContext } from 'react';
import { useImmer } from 'use-immer';
import { TextOkButton } from '../../../../common/button/TextOkButton';
import { EntityFormContainer } from '../../../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../../../common/form/formContentPanel/FormContentPanelContainer';
import { FixSelectFormItem } from '../../../../common/form/inputFormItems/FixSelectFormItem';
import { InputFormItem } from '../../../../common/form/inputFormItems/InputFormItem';
import { AxiosContext, AxiosContextType } from '../../../../context/axios.context';
import { sNavigateReload } from '../../../../utils/safeNavigation';
import { ADMIN } from '../../../user/UserRoutesDef';
import { ResultPanel } from '../../common/ResultPanel';
import { ButtonsFormItemLayout } from '../../../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { DisabledContextProviderAlwaysEditableAdmin } from '../../../../common/rights/DisabledContextProviderAlwaysEditableAdmin';
import { InputPasswordFormItem } from '../../../../common/form/inputFormItems/InputPasswordFormItem';
// @ts-ignore

const Style = styled.div``;

/**
 * Page de creation directe d'un candidat et de son dossier vierge par l'administrateur quand la plateforme est fermee
 * @param props
 * @constructor
 */
export function CandidateEditPage(props) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  const [state, updateState] = useImmer({
    captcha: false,
    valid: false,
    loading: false,
    error: undefined as string,
    result: undefined as string,
  });

  const onFinish = async (values) => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await axios.post('/administration/createCandidate', values);
      updateState((draft) => {
        draft.result = 'Candidat créé';
        draft.error = undefined;
      });
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await sleep(2500);
      sNavigateReload(ADMIN.CANDIDATE_EDIT);
    } catch (ex) {
      updateState((draft) => {
        draft.error = ex.response.data.message;
        draft.result = undefined;
      });
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };

  return (
    <Style>
      <EntityFormContainer
        entity="candidate"
        initialValues={{ ...props, rights: DEFAULT }}
        noSave
        layout="vertical"
        onFinish={onFinish}
      >
        <DisabledContextProviderAlwaysEditableAdmin>
          <FormContentPanelContainer partName="candidate">
            <FixSelectFormItem
              propertyName="gender"
              options={[
                { value: 'm', label: 'Masculin' },
                { value: 'f', label: 'Féminin' },
                { value: 'o', label: 'Autre' }]}
            />
            <InputFormItem
              propertyName="firstName"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' }]}
            />
            <InputFormItem
              propertyName="lastName"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' }]}
            />
            <InputFormItem
              propertyName="email"
              autoComplete="email"
              required
              rules={[{ required: true, message: 'Champ obligatoire !' },
              { type: 'email' }]}
            />
            <InputPasswordFormItem
              propertyName="password"
              required
              rules={[{ min: 10, required: true }]}
              autoComplete="off" />
            <ButtonsFormItemLayout>
              <SubmitButton />
            </ButtonsFormItemLayout>
          </FormContentPanelContainer>
        </DisabledContextProviderAlwaysEditableAdmin>
      </EntityFormContainer>
      <ResultPanel error={state.error} result={state.result} />
    </Style>
  );
}

function SubmitButton() {
  return (
    <TextOkButton
      tkey="candidate.add"
      htmlType="submit"
    />
  );
}
