/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import { IRights, IVersionedBaseStringId, RightsUtils } from '@fstn/ecandidaturev2_api-interfaces';
import { ColProps } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { FormLabelAlign } from 'antd/lib/form/interface';
import _ from 'lodash';
import React, { Fragment, useContext, useRef } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
// @ts-ignore
import useMobileDetect from 'use-mobile-detect-hook';
import { EntityFormContext } from '../../../context/entityForm.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { ValidationsContext } from '../../../context/validations.context';
import { useResponsiveForm } from '../../../hooks/use-responsive-form.hook';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { deepCloneRightsForSourceChanges } from '../../../utils/deepCloneRightsForSourceChanges';
import { formListPropertyPathToKey } from '../../../utils/formListPropertyPathToKey';
import { formValidationMessage } from '../../../utils/formValidationMessage';
import { getValidationRequired } from '../../../utils/formValidationRequired';
import { getValidationStatus } from '../../../utils/formValidationStatus';
import * as Locale from '../../locale';
import { normalize } from '../utils/ValueNormalizer';
import { EntityFormItem } from './EntityFormItem';
import { DisabledContext } from '../../rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { formValidationGetter } from '../../../utils/formValidationGetter';

const Style = styled('span')`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:pre-wrap;
    text-align: left;
    display: inline!important;
`;

/**
 * EntityFormItemContainer: Form Item to use inside EntityForm
 * Provides validations for Inputs
 * @see EntityFormContainer
 * @param props
 * depency is a hack to allow the reload
 * @constructor
 */
export function EntityFormItemContainer<T extends IVersionedBaseStringId>(props: ItemProps & EntityFormProps & { layout?: 'vertical' | 'horizontal', dependenciesValues?: any[] }) {
    // console.log('Redraw EntityFormItemContainer', props);
    const { t } = useSafeTranslation();
    const { userCtx, canEditValidation } = useContext<UserContextType>(UserContext);

    const entity = useScopedSelector<any[], string>('EntityFormItemContainer', EntityFormContext, (c) => c[0]?.entity);
    const formListPropertyName = useScopedSelector<any[], any>('EntityFormItemContainer', EntityFormListContext, (c) => c[0]?.propertyName);
    const formListEntityIdsValue = useScopedSelector<any[], any>('EntityFormItemContainer', EntityFormContext, (c) => c[0]?.entityValue?.[formListPropertyName]?.map((e) => ({ id: e?.id })));
    const rights = useScopedSelector<any[], IRights>('EntityFormItemContainer_rights', EntityFormContext, (c) => c[0]?.entityValue?.rights);
    const secretaryReviewState = useScopedSelector<any[], IRights>('EntityFormItemContainer_secretaryReviewState', EntityFormContext, (c) => c[0]?.entityValue?.secretaryReview?.state);
    const entityFormItemValidations = useScopedSelector(`EntityFormItemContainer_entityValidations_${props.propertyName}`, ValidationsContext,
        (validationsCtx) => formValidationGetter<T>(((validationsCtx.validationsCtxState?.[validationsCtx?.steps?.[0]])?.validations?.[entity]), props.propertyName));
    const validationRulesForFormItem = useScopedSelector('EntityFormItemContainer_entityValidationsRules', ValidationsContext,
        (validationsCtx) => formValidationGetter<T>(((validationsCtx.validationsCtxState?.[validationsCtx.steps?.[0]])?.validationsRules?.[entity]), props.propertyName));

    const validateStatus = useScopedSelector<any[], string>(`EntityFormItemContainer_validationStatus_${props.propertyName}`, EntityFormContext,
        (c) => getValidationStatus(props.skipRerenderOnValidateChange
            ? () => false : c[0]?.isFieldTouched,
            c[0]?.getFieldValue,
            entityFormItemValidations,
            validationRulesForFormItem,
            props?.propertyName));
    const getFieldsValue = useScopedSelector<any[], Function>('EntityFormItemContainer_getFieldsValue', EntityFormContext, (c) => c[0].getFieldsValue);
    const cancelCurrentChanges = useScopedSelector<any[], Function>('EntityFormItemContainer_cancelCurrentChanges', EntityFormContext, (c) => c[0].cancelCurrentChanges);
    const setFieldsValue = useScopedSelector<any[], Function>('EntityFormItemContainer_setFieldsValue', EntityFormContext, (c) => c[0].setFieldsValue);
    const onImmediateChange = useScopedSelector<any[], Function>('EntityFormItemContainer_onImmediateChange', EntityFormContext, (c) => c[0].onImmediateChange);
    const disabled = useScopedSelector('EntityFormItemContainer_disabled', DisabledContext, (c) => c?.disabled);
    const detectMobile = useMobileDetect();
    const helpMessage = useScopedSelector(`EntityFormItemContainer_entityValidations_help_${props.propertyName}`, ValidationsContext,
        (validationsCtx) => formValidationMessage(entity, (validationsCtx.validationsCtxState?.[validationsCtx?.steps?.[0]])?.validations?.[entity], props?.propertyName, t));

    const required = props.required || getValidationRequired(entityFormItemValidations, validationRulesForFormItem, props?.propertyName);

    // @ts-ignore
    const responsive = useResponsiveForm(props);
    const oldProps = useRef({});
    const oldItemProps = useRef({});
    const canShowDisabledPopup = canEditValidation() && ((entity !== 'candidate-file') && (entity !== 'programs-list') && entity !== 'payments-list');
    let itemProps: any = {
        name: formListPropertyPathToKey(props?.propertyName),
        label: props.label !== undefined ? props.label : (
            <Fragment>
                <Locale.Label
                    tkey={`${entity}.${props?.propertyName}`}
                />
                {(!required && props?.rules)
                    ? <Locale.Suffix tkey="optional" /> : ''}
            </Fragment>
        ),
        ...responsive.colProps,
        layout: props.layout || responsive.layout,
        help: <Fragment />,
        dependencies: props.dependencies,
        shouldUpdate: props.shouldUpdate,
        validateStatus,
        hasFeedback: props.hasFeedback !== undefined ? props.hasFeedback : true,
        normalize: normalize(entity, props?.propertyName),
        labelAlign: 'right' as FormLabelAlign,
        validateTrigger: props.validateTrigger || 'onChange',
        required,
        rules: [
            {
                /* validator: (rule, value, callback) => {
                     if (hasValidationsErrors(entity, entityValidations, props?.propertyName, t)) {
                         callback(new Error(''));
                     } else {
                         callback();
                     }
                 }, */
                required,
            },
        ],
        /* onChange:props.onChange, */
        onChange: async (e) => {
            await props?.onChange?.(e);
        },
        onAfterChange: async (e) => {
            await props?.onAfterChange?.(e);
        },
        css: props.css,
        labelCol: props.labelCol || { span: 8 },
        wrapperCol: props.wrapperCol || { span: 16 },
    };

    if (helpMessage) {
        if (detectMobile.isMobile()) {
            itemProps.help = helpMessage;
        } else {
            itemProps.tooltip = <span dangerouslySetInnerHTML={{ __html: helpMessage }} />;
        }
    }

    itemProps = { ...itemProps, ..._.omit(_.omitBy(props, _.isUndefined), 'propertyName') };

    // register form
    if (props?.rules) {
        delete itemProps.validateStatus;
        delete itemProps.normalize;
    }

    async function edit() {
        // need to cancel first, because the security check on the server will not able to update rights with other data
        cancelCurrentChanges();
        // change parent right
        const rightsToChange = { rights: RightsUtils.toEdit(rights, userCtx.user?.role?.name) };
        if (formListPropertyName) {
            // change list entity rights
            if (!formListEntityIdsValue) {
                throw new Error(`Unable to switch rights, missing ${formListPropertyName} on ${JSON.stringify(formListEntityIdsValue)}`);
            }
            rightsToChange[`${formListPropertyName}`] = formListEntityIdsValue.map?.((e) => ({
                id: e.id,
                rights: RightsUtils.toEdit(rights, userCtx.user?.role?.name),
            }));
        }
        /**
         * On doit setter également les valeurs dans le formulaire pour eviter le switch readOnly/edit sur le save du formulaire
         */
        const v = { ...getFieldsValue() };
        deepCloneRightsForSourceChanges(v, rightsToChange);
        setFieldsValue(v);
        await onImmediateChange(rightsToChange);
        // await loadRights()
    }

    // Property name of motivation letter is something like[        "programLines",        0,        "motivationLetter"    ]
    const newProps = [canShowDisabledPopup, disabled, itemProps.validateStatus, itemProps.tooltip, itemProps.disabled, props.propertyName];
    return useDeepCompareMemo(() => {
        const newItemProps = [canShowDisabledPopup, disabled, itemProps.validateStatus, itemProps.tooltip, itemProps.disabled, props.propertyName, props.children];
        oldItemProps.current = newItemProps;
        oldProps.current = newProps;
        return (
            <EntityFormItem
                canShowDisabledPopup={canShowDisabledPopup}
                disabled={disabled}
                edit={edit}
                itemProps={itemProps}

            >
                {props.children}
            </EntityFormItem>
        );
    },
        /* Reloa donly when validation status change, here when statye and tooltip change */
        [canShowDisabledPopup/* OK */, disabled/* OK */, secretaryReviewState, itemProps.validateStatus/* OK */, itemProps.disabled, props.propertyName/* OK */, ...(props.dependenciesValues || [])]);
}

export interface ItemProps extends Partial<FormItemProps> {
    propertyName: string | any[] | number,
    options?: { value, label }[],
    onChange?: any,
    onBlur?: any,
    labelCol?: ColProps,
    wrapperCol?: ColProps,
    dependenciesValues?: any[]
}

export type EntityFormProps = {
    readOnly?: boolean,
    onAfterChange?: Function,
    onBeforeChange?: Function,
    css?: any,
    skipRerenderOnValidateChange?: boolean
};

export function copyEntityFormProps(props) {
    return {
        name: formListPropertyPathToKey(props.propertyName as string),
        readOnly: props.readOnly,
        onAfterChange: props.onAfterChange,
        onBeforeChange: props.onBeforeChange,
        onChange: props.onChange,
        onBlur: props.onBlur,
        propertyName: props.propertyName,
        rules: props.rules,
        required: props.required,
        initialValue: props.initialValue,
        hidden: props.hidden,
        label: props.label !== undefined ? (<Style>{props.label}</Style>) : undefined,
        css: props.css,
        className: props.className,
        labelCol: props.labelCol || { span: 8 },
        layout: props.layout,
        wrapperCol: props.wrapperCol || { span: 16 },
        dependencies: props.dependencies,
        dependenciesValues: props.dependenciesValues,
    };
}
