/** @jsx jsx */
import {IPaymentsList, walkEntityPropertyAndMapDateStrAsMoment} from '@fstn/ecandidaturev2_api-interfaces';
import {jsx} from '@emotion/core';
import {Timeline} from 'antd';
import _ from 'lodash';
import React from 'react';
import {useDeepCompareEffect} from 'use-deep-compare';
import {useImmer} from 'use-immer';
import {useEntity} from '../../../hooks/use-entity';
import {PaymentHistoryLine} from './PaymentForm';

export function PaymentHistory(props: { paymentsList?:IPaymentsList }) {
    const [state, updateState] = useImmer({ payments: props?.paymentsList?.payments, loaded: false });
    const { loadEntity } = useEntity();

    async function load() {
        updateState((draft) => {
            draft.loaded = true;
        });
        const paymentsList: IPaymentsList = await loadEntity('payments-list');
        walkEntityPropertyAndMapDateStrAsMoment(paymentsList);
        const { payments } = paymentsList;
        updateState((draft) => {
            draft.payments = payments;
        });
    }

    useDeepCompareEffect(() => {
        if (!state.loaded && (!state.payments || !_.isEqual(state.payments, props?.paymentsList?.payments))) {
            load().then();
        }
    }, [state.payments, props.paymentsList]);

    return (
      <Timeline>
        {state.payments?.map?.((p) => <PaymentHistoryLine payment={p} />)}
      </Timeline>
);
}
