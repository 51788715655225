/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { DocumentTypeEnum, IAdditionalDocument } from '@fstn/ecandidaturev2_api-interfaces';
import Card from 'antd/lib/card';
import React from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import UUID from 'uuid-js';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import { UploadFormItemContainer } from '../../../common/form/inputFormItems/uploadFormItem/UploadFormItemContainer';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

export function AdditionalDocumentForm() {
  const formListEntity = useScopedSelector<any[], string>('IAdditionalDocument', EntityFormListContext, (c) => c[0]?.entity);
  const field = useScopedSelector<any[], any>('IAdditionalDocument', EntityFormListContext, (c) => c[0].field);
  const formListEntityValue = useScopedSelector<any[], IAdditionalDocument>('AdditionalDocumentForm', EntityFormListContext, (c) => c[0]?.entityValue);

  const { t } = useSafeTranslation();

  const id = formListEntityValue?.id;
  const name = formListEntityValue?.name;

  function getDocumentTitle() {
    return `${t('additional.document.title')} ${formListEntityValue?.name || ''}`;
  }

  const addDocument = useDeepCompareCallback(() => ({
    id: UUID.create(1).toString(),
    name,
    type: DocumentTypeEnum.ADDITIONAL,
    parentType: formListEntity,
    parentId: id,
  }), [formListEntity, id, name]);

  return (
    <Card
      title={getDocumentTitle()}
      key={id}
      css={css``}
    >
      <div>
        <div>
          <DisabledContextProvider value={{
            disabled: formListEntityValue?.mandatory === true || undefined,
            wrapAuthorized: true,
            context: 'AdditionalDocumentForm',
          }}
          >
            <InputFormItem
              propertyName={['additionalDocuments', field.name, 'name']}
            />
          </DisabledContextProvider>
        </div>
        {formListEntityValue?.name
          && (
            <div>
              <UploadFormItemContainer
                propertyName={['additionalDocuments', field.name, 'document']}
                accept={'image/*,.pdf'}
                factory={addDocument}
              />
            </div>
          )}
      </div>
    </Card>
  );
}
