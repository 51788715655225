import { FilePdfOutlined } from '@ant-design/icons';
import React, { Fragment, useContext } from 'react';
import { documentDownload } from '../../utils/documentDownload';
import { TextNeutralButton } from '../../common/button/TextNeutralButton';
import { RightAreaContext, RightAreaContextProviderType } from '../canSeeCandidateFile/RightAreaContext';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';

export function DocumentHeaderButton() {
  const rightAreaContext = useContext<RightAreaContextProviderType>(RightAreaContext);
  const { axios, token } = useContext<AxiosContextType>(AxiosContext);
  return (
    <Fragment>
      {rightAreaContext.rightAreaContext.document
        && (
          <TextNeutralButton
            icon={<FilePdfOutlined />}
            onClick={async (e) => {
              e.stopPropagation();
              await documentDownload(axios, token, rightAreaContext.rightAreaContext.document);
            }}
            tkey={`${rightAreaContext.rightAreaContext.document?.parentType}_${rightAreaContext.rightAreaContext.document?.name}`}
          />
        )}
    </Fragment>
  );
}
