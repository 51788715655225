import { RadioProps } from 'antd';
import Radio from 'antd/lib/radio';
import React from 'react';

import { DisabledContext } from '../../rights/DisabledContext';
import { copyEntityFormProps, EntityFormItemContainer, ItemProps } from '../entityForm/EntityFormItemContainer';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';

/**
 * EntityFormItemContainer for radio form item with fixed number of options define staticaly
 * @param props
 * @constructor
 */
export function FixRadioFormItem(props: ItemProps & Omit<RadioProps, 'disabled'>) {
    return (
      <EntityFormItemContainer
        {...copyEntityFormProps(props)}
      >
        <FixRadioFormItemContentContainer
          data-testid={`${props.propertyName}`?.replace(/,/g, '_')}
          options={props.options}
        />
      </EntityFormItemContainer>
        );
}

function FixRadioFormItemContentContainer(props: { value?: any, onChange?: any, options: any }) {
    const disabled = useScopedSelector('FixRadioFormItemContentContainer', DisabledContext, (c) => c?.disabled);
    return (
      <Radio.Group
        value={props.value}
        onChange={props.onChange}
        data-testid={props['data-testid']}
        className={props['data-testid']}
        disabled={disabled}
      >
        {props.options?.map?.(({ value, label }) => (
          <Radio
            key={value}
            data-testid={value}
            value={value}
          >
            {label}
          </Radio>
            ))}
      </Radio.Group>
);
}
