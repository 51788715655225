/** @jsx jsx */
import { DeleteOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/core';
import { IAcademicReferentReview, ReferentReviewStatusEnum, RightsUtils, UserRoleEnum } from '@fstn/ecandidaturev2_api-interfaces';
import { Collapse } from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import React, { Fragment, useContext } from 'react';

import { TextDangerButton } from '../../../common/button/TextDangerButton';
import { EntityFormItemContainer } from '../../../common/form/entityForm/EntityFormItemContainer';
import { InputFormItem } from '../../../common/form/inputFormItems/InputFormItem';
import * as Locale from '../../../common/locale';
import { EntityFormContext } from '../../../context/entityForm.context';
import { EntityFormListContext } from '../../../context/entityFormList.context';
import { UserContext, UserContextType } from '../../../context/user.context';
import { useSafeTranslation } from '../../../hooks/use-safe-translation';
import { IsExaminer } from '../../user/conditionnal/IsExaminer';
import { IsSpecializedSecretary } from '../../user/conditionnal/IsSpecializedSecretary';
import { IsSuperSecretary } from '../../user/conditionnal/IsSuperSecretary';
import { DisabledContextProvider } from '../../../common/rights/DisabledContext';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { ReferentReviewEntityForm } from '../../referent/ReferentReviewEntityForm';
import { StrictLoading } from '../../../common/indicator/SmartLoading';
import { IsSecretaryOrSpecializedOrExaminer } from '../../user/conditionnal/IsSecretaryOrSpecializedOrExaminer';

// noinspection JSUnusedLocalSymbols
export function AcademicReferentReviewForm(props: {}) {
  const getFieldValue = useScopedSelector<any[], any>('AcademicReferentReviewForm', EntityFormContext, (c) => c[0]?.getFieldValue);
  const formListPropertyName = useScopedSelector<any[], any>('AcademicReferentReviewForm', EntityFormListContext, (c) => c[0]?.propertyName);
  const remove = useScopedSelector<any[], Function>('AcademicReferentReviewForm', EntityFormListContext, (c) => c[0]?.remove);

  const field = useScopedSelector<any[], any>('AcademicReferentReviewForm', EntityFormListContext, (c) => c[0].field);
  const referentFirstName = useScopedSelector<any[], IAcademicReferentReview>('AcademicReferentReviewForm', EntityFormListContext,
    (c) => c[0]?.entityValue?.referent?.firstName);
  const referentLastName = useScopedSelector<any[], IAcademicReferentReview>('AcademicReferentReviewForm', EntityFormListContext,
    (c) => c[0]?.entityValue?.referent?.lastName);
  const referentJobTitle = useScopedSelector<any[], IAcademicReferentReview>('AcademicReferentReviewForm', EntityFormListContext,
    (c) => c[0]?.entityValue?.referent?.jobTitle);
  const referentSchool = useScopedSelector<any[], IAcademicReferentReview>('AcademicReferentReviewForm', EntityFormListContext,
    (c) => c[0]?.entityValue?.referent?.school?.name);
  const referentCity = useScopedSelector<any[], IAcademicReferentReview>('AcademicReferentReviewForm', EntityFormListContext,
    (c) => c[0]?.entityValue?.referent?.school?.city?.name);
  const referentId = useScopedSelector<any[], IAcademicReferentReview>('AcademicReferentReviewForm', EntityFormListContext,
    (c) => c[0]?.entityValue?.referent?.id);

  const { t } = useSafeTranslation();
  const userContext = useContext<UserContextType>(UserContext);

  function getReferentReviewTitle() {
    return `${t('academic.referentReview.title')} 
        ${referentFirstName || ''} 
        ${referentLastName || ''} 
        ${referentJobTitle || '-'}, 
        ${referentSchool || '-'}, 
        ${referentCity || '-'}`;
  }

  // noinspection RequiredAttributes
  return (
    <StrictLoading loading={!referentId}>
      <Collapse
        ghost
        key={`${field.name}field`}
      >
        <Collapse.Panel
          header={(
            <Fragment>
              {getReferentReviewTitle()}
            </Fragment>
          )}
          key={`${field.name}`}
          css={css`
            .referent-review-container .ant-radio-wrapper{
                width: 100% !important;
                max-width: 100% !important;
                min-width: 100% !important;
            }
            .referent-review-container .referent-review-notation-container{
              display: block;
                .ant-form-item{
                    width: 100% !important;
                    max-width: 100% !important;
                    min-width: 100% !important;
                }
            }`}
        >

          <DisabledContextProvider value={{
            disabled: true,
            wrapAuthorized: true,
            context: 'AcademicReferentReviewForm',
          }}
          >
            <InputFormItem
              propertyName={[formListPropertyName, field.name, 'referent', 'email']}
            />
            {userContext.userCtx.user?.role?.name !== UserRoleEnum.SPECIALIZED_SECRETARY
              && userContext.userCtx.user?.role?.name !== UserRoleEnum.EXAMINER
              && userContext.userCtx.user?.role?.name !== UserRoleEnum.JURY
              && (
                <Fragment>
                  <InputFormItem propertyName={[formListPropertyName, field.name, 'referent', 'firstName']} />
                  <InputFormItem propertyName={[formListPropertyName, field.name, 'referent', 'lastName']} />
                </Fragment>
              )}
          </DisabledContextProvider>

          <IsSecretaryOrSpecializedOrExaminer>
            <EntityFormItemContainer propertyName={[formListPropertyName, field.name, 'status', 'id']}>
              <Locale.Content tkey={`${formListPropertyName}.${getFieldValue([formListPropertyName, field.name, 'status', 'id'])}`} />
            </EntityFormItemContainer>
          </IsSecretaryOrSpecializedOrExaminer>

          <IsSuperSecretary>
            <ReferentReviewEntityForm />
          </IsSuperSecretary>

          <IsSpecializedSecretary>
            {getFieldValue([formListPropertyName, field.name, 'status', 'id']) === ReferentReviewStatusEnum.SUBMIT &&
              <ReferentReviewEntityForm />
            }
          </IsSpecializedSecretary>
          <IsExaminer>
            <ReferentReviewEntityForm />
          </IsExaminer>
          {RightsUtils.isAuthorizeForTable(userContext?.userCtx?.user?.rightsOverriding?.write, 'academic_referent_review')
            && (
              <Popconfirm
                title={(
                  <Locale.Button
                    tkey="academicReferentReviews.removeReferent.confirm"
                  />
                )}
                onConfirm={() => remove()}
              >
                <TextDangerButton
                  tkey="academicReferentReviews.removeReferent"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            )}
        </Collapse.Panel>
      </Collapse>
    </StrictLoading>
  );
}
