/* eslint-disable react-hooks/exhaustive-deps */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IAcademicReferent, IAcademicReferentReview } from '@fstn/ecandidaturev2_api-interfaces';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext, useEffect } from 'react';
import { Box, Flex } from 'rebass';
import { useImmer } from 'use-immer';

import { TextOkButton } from '../../common/button/TextOkButton';
import { EntityFormContainer } from '../../common/form/entityForm/EntityFormContainer';
import { FormContentPanelContainer } from '../../common/form/formContentPanel/FormContentPanelContainer';
import { FormStyle } from '../../common/form/FormStyle';
import { FixSelectFormItem } from '../../common/form/inputFormItems/FixSelectFormItem';
import { InputFormItem } from '../../common/form/inputFormItems/InputFormItem';
import { InputPasswordFormItem } from '../../common/form/inputFormItems/InputPasswordFormItem';
import { SchoolAutoCompleteFormItem } from '../../common/form/inputFormItems/SchoolAutoCompleteFormItem';
import { WithInitialLoad } from '../../common/withExtraParameters/WithInitialLoad';
import { EntityFormContext, EntityFormContextType } from '../../context/entityForm.context';
import { EntityFormListContext } from '../../context/entityFormList.context';
import { IhmContext } from '../../context/ihm.context';
import { UserContext, UserContextType } from '../../context/user.context';
import { ValidationsContext } from '../../context/validations.context';
import { useEntity } from '../../hooks/use-entity';
import { useResponsiveForm } from '../../hooks/use-responsive-form.hook';
import { useSafeTranslation } from '../../hooks/use-safe-translation';
import { useUser } from '../../hooks/use-user.hook';
import { useValidation } from '../../hooks/use-validation.hook';
import { DisabledContextProvider } from '../../common/rights/DisabledContext';
import { ButtonsFormItemLayout } from '../../common/form/buttonsFormItem/ButtonsFormItemLayout';
import { useScopedSelector } from '../../hooks/use-scoped-selector';
import { DisabledContextProviderAlwaysEnabled } from '../../common/rights/DisabledContextProviderAlwaysEnabled';
import { StrictLoading } from '../../common/indicator/SmartLoading';

const entity = 'academic-referent';

export function ReferentRegisterEntityForm(props: {}) {
  const { t } = useSafeTranslation();
  const [state,
    updateState] = useImmer({
      alreadyValidated: false,
      captcha: false,
      valid: false,
      loading: false,
      schoolTrack: '',
      school: '',
    });
  const entityValue = useScopedSelector<any[], EntityFormContextType<IAcademicReferentReview>>('ReferentRegisterEntityForm', EntityFormContext, (c) => c[0]?.entityValue);
  const withUpdateValidations = useScopedSelector('EntityFormContainer', ValidationsContext, (c) => c.withUpdateValidations);
  const steps = useScopedSelector('ReferentRegisterEntityForm', ValidationsContext, (c) => c.steps);

  const { validate } = useValidation();
  const formListEntityId = useScopedSelector<any[], any>('ReferentRegisterEntityForm', EntityFormListContext, (c) => c[0]?.entityValue?.id);

  const responsive = useResponsiveForm();

  useEffect(() => {
    async function load() {
      await validate(entity, entityValue, withUpdateValidations, steps);
    }

    if (!state.alreadyValidated) {
      updateState((draft) => {
        draft.alreadyValidated = true;
      });
      load().then(() => {
      });
    }
  }, [entityValue, state.alreadyValidated, updateState, validate]);
  // loop add

  return (
    <StrictLoading loading={!formListEntityId} context="ReferentRegisterForm">
      <Flex sx={{ flexWrap: 'wrap' }}>
        <Box {...responsive.w1}>
          <FormContentPanelContainer partName={entity}>
            <FixSelectFormItem
              propertyName="gender"
              options={[
                { value: 'm', label: t('gender.male') },
                { value: 'f', label: t('gender.female') },
                { value: 'o', label: t('gender.other') }]}
            />
            <InputFormItem propertyName="firstName" />
            <InputFormItem
              propertyName="lastName"
            />

            <InputFormItem
              propertyName="phone"
              autoComplete="phone"
            />
            <DisabledContextProvider value={{ disabled: true, wrapAuthorized: true }}>
              <InputFormItem
                propertyName="email"
                autoComplete="email"
              />
            </DisabledContextProvider>
            <InputPasswordFormItem
              propertyName="password"
              autoComplete="new-password"
            />

            <InputFormItem
              propertyName="jobTitle"
              autoComplete="jobTitle"
            />
            <SchoolAutoCompleteFormItem
              onChange={(v) => updateState((draft) => {
                draft.school = v;
              })}
              // disabled={false}
              propertyName="school"
            />
            <ButtonsFormItemLayout>
              <TextOkButton
                tkey="register"
                type="primary"
                htmlType="submit"
              />
            </ButtonsFormItemLayout>
          </FormContentPanelContainer>
        </Box>
      </Flex>
    </StrictLoading>
  );
}

export function ReferentRegisterForm() {
  const userCtx = useContext<UserContextType>(UserContext);
  const { loadMe, cookie } = useUser(userCtx);
  const { startDebounced, completeDebounced } = useContext(IhmContext);
  const { patchEntity } = useEntity<IAcademicReferent>();

  const onFinish = async () => {
    startDebounced();
    try {
      await patchEntity(entity, userCtx?.userCtx?.user as any, { registered: true });
      await cookie();
      await loadMe();
      window.location.reload();
    } finally {
      completeDebounced();
    }
  };

  const onBeforeValidate = (values: IAcademicReferent | any, differences: Partial<IAcademicReferent>) => differences;
  return (
    <FormStyle>
      <WithInitialLoad entity={entity} entityId={userCtx.userCtx?.user?.id}>
        <EntityFormContainer listeners={{ onBeforeValidate }} onFinish={onFinish}>
          <DisabledContextProviderAlwaysEnabled>
            <ReferentRegisterEntityForm />
          </DisabledContextProviderAlwaysEnabled>
        </EntityFormContainer>
      </WithInitialLoad>
    </FormStyle>
  );
}
