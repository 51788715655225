/** @jsx jsx */
import StringFilter from '@inovua/reactdatagrid-community/StringFilter';
import { TypeCellProps, TypeComputedProps, TypeRowProps } from '@inovua/reactdatagrid-community/types';
import { MutableRefObject, useCallback } from 'react';
import _, { flatMap } from 'lodash';
import { css, jsx } from '@emotion/core';
import { useImmer } from 'use-immer';
import { useDeepCompareEffect } from 'use-deep-compare';
import { IAcademicReferent } from '@fstn/ecandidaturev2_api-interfaces';
import { Checkbox } from 'antd';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { ColdDataAutoCompleteContainer } from '../../../../common/coldData/ColdDataAutoCompleteContainer';
import { ReferentContextMenu } from './ReferentContextMenu';
import { CustomGridSelectEditor } from '../../../../common/customDatagrid/customGridSelectEditor';
import { useColdData } from '../../../../hooks/use-cold-data.hook';
import { getSelectedElements } from '../../common/AdminEditableList';

// Contiendra la liste des formations pour alimentation des combos (filtres et edition)
let listSchools = [];

/**
 * Fonction appelee pour la generation de la combo pour filtrage des etablissements
 * Elle en profite pour alimenter listSchools qui est utilisé pour afficher les combos en edition
 * @param props
 * @returns
 */
function SchoolFilter(props: { onChange: Function, filterValue: any }) {
    // Mapper utilisé pour générer nom formation + option dans la combo de filtrage (ColdDataSelectorContainer ou il faut id et name)
    const mapper = useCallback((p) => ({
        id: p.id, name: p.name,
    }), []);
    // Mapper utilisé pour générer nom formation + option dans la combo de selection (il faut id et label)
    const mapperCombo = useCallback((p) => ({
        id: p.id, label: p.name,
    }), []);
    // Recuperation des etablissements
    const [coldData] = useColdData('public/schools');
    const [state, updateState] = useImmer({
        listSchools: [],
    });

    useDeepCompareEffect(() => {
        let { data } = coldData;
        // Tri des formations
        data = _.sortBy(data, 'name');
        // Application du mapper
        data = flatMap(data, mapperCombo);
        updateState((draft) => {
            draft.listSchools = data;
        });
    }, [coldData]);
    // Mise a jour du tableau utilisé par le customGridSelecctEditor et le render de la colonne
    listSchools = state.listSchools;

    // La ligne css={css`.ant-select-selector {width: 300px !important}`} ci-dessous permet de rajouter directement du css
    // dans une classe existante utilisée. Ici, on ajoute width sur la classe ant-select-selector pour outrepasser son mode
    // "autoadapatif par defaut". Le !important permet de signaler que cette nouvelle propriete ne doit pas etre "redefinie" par
    // d'eventuelles nouvelles props appliquées ensuite
    return (
      <div className="InovuaReactDataGrid__column-header__filter-wrapper" css={css`.ant-select-selector {width: 200px !important}`}>
        <ColdDataAutoCompleteContainer
          api="schools"
          params="{ style: { width: '200px' } }"
          allowClear
          mapper={mapper}
          onChange={(v) => {
                    props.onChange({ ...props.filterValue, value: v });
                }}
        />
      </div>
    );
}

export const referentListInitialColumns = () => [
    {
        header: 'Prénom',
        name: 'firstName',
        width: 200,
        defaultLocked: 'start',
        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'Nom',
        name: 'lastName',
        width: 200,
        defaultLocked: 'start',
        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'E-mail',
        name: 'email',
        className: 'email',
        'test-id': 'email',
        width: 250,

        editable: true,
        style: { textAlign: 'left' },
        filterEditor: StringFilter,
        filterEditorProps: {
            className: 'emailFilter',
            'test-id': 'emailFilter',
        },

    }, {
        header: 'Téléphone',
        name: 'phone',
        width: 150,

        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'Fonction',
        name: 'jobTitle',
        width: 200,

        editable: true,
        style: { textAlign: 'left' },

    }, {
        header: 'Etablissement (recherche par liste)',
        name: 'school',
        width: 400,

        style: { textAlign: 'left' },
        lockable: false,
        render: ({ value }) => listSchools.find((element) => element.id === value)?.label,
        filterEditor: SchoolFilter,
        // Il faut passer le dataSource dans le return, si on le passe dans le editorProps, ca ne marche pas (pas de rerender ?)
        renderEditor: (editorProps) => <CustomGridSelectEditor {...editorProps} dataSource={listSchools} />,
        editorProps: {
            idProperty: 'school',
        },
    },
    {
        header: 'Etablissement (recherche libre)',
        name: 'school2',
        width: 400,
        editable: false,
        visible: false,
        style: { textAlign: 'left' },
        lockable: false,
    }, {
        header: 'RGPD ?',
        name: 'rgpd',
        width: 90,
        // @ts-ignore
        filterEditor: BoolFilter,
        filterable: true,
        render: ({ value, data }: any) => <Checkbox key={`particularCase_cell_${data.id}`} disabled checked={value} />,
        /* render: ({ value }) => {
            if (value === true) return 'Accepté';
            if (value === false) return 'Refusé';
            return 'Non défini';
        }, */

        editable: false,
        style: { textAlign: 'center' },

    }, {
        header: 'Demandes reco',
        name: 'countReviews',

        width: 150,
        lockable: false,
        filterable: true,
        editable: false,
        render: ({ value }) => (`${value?.length} demandes`),
    },
];

export const referentListInitialFilters = [
    {
        name: 'firstName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'lastName', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'email', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'phone', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'jobTitle', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'school', operator: 'listSchools', type: 'listSchools', value: '',
    },
    {
        name: 'school2', operator: 'contains', type: 'string', value: '',
    },
    {
        name: 'rgpd', operator: 'eq', type: 'boolean', value: null, emptyValue: null,
    },
];

export function referentListRenderRowContextMenu(menuProps: any, details: {
    rowProps: TypeRowProps;
    cellProps: TypeCellProps;
    grid: any;
    computedProps: TypeComputedProps;
    computedPropsRef: MutableRefObject<TypeComputedProps>;
}, selectedUsers, gridRef) {
    return (
      <ReferentContextMenu
        menuProps={menuProps}
        details={details}
        selectedUsers={getSelectedElements(details.rowProps.data, selectedUsers)}
        gridRef={gridRef}
      />
    );
}

/**
 * Map result to row
 * @param d
 */
export function referentListMapToRow(d: IAcademicReferent) {
    const row: any = {
        id: d.id,
        firstName: d.firstName,
        lastName: d.lastName,
        email: d.email,
        phone: d.phone,
        jobTitle: d.jobTitle,
        school: d.school?.id, // Utilise pour affichage et editable en combobox
        school2: d.school?.name, // Utilise (non editable) pour recherche libre sur les nom d'ecole
        rgpd: d.rgpd,
        countReviews: d.reviews,
    };
    return row;
}
