/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import {
  SecretaryReviewStatusEnum,
} from '@fstn/ecandidaturev2_api-interfaces';
import { Form, Switch } from 'antd';
import Radio from 'antd/lib/radio';
import { useContext } from 'react';

import { AxiosContext, AxiosContextType } from '../../../context/axios.context';
import { EntityFormContext } from '../../../context/entityForm.context';
import {
  CandidateFileContext,
  CandidateFileContextProviderType,
} from '../../../pages/canSeeCandidateFile/CandidateFileContext';
import { Colorized } from '../../antd/ColorizedComponent';
import { HideWhenSmallScreen } from '../../withExtraParameters/HideWhenSmallScreen';
import * as Locale from '../../locale';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { DisabledContextProvider } from '../../rights/DisabledContext';
import { UserContext } from '../../../context/user.context';
import { isSuperSecretary } from '../../../pages/user/conditionnal/IsSuperSecretary';

const RadioButtonGroup = styled(Radio.Group)`
display:flex;
.ant-radio-button-wrapper{
    text-overflow: ellipsis;
}`;

/**
 * SecretaryReviewFormContainer: Secretary Review Form
 * Displayed on bottom of each form to wrap secretary review
 * @param state
 * @param updateState
 * @param onChange
 * @constructor
 */
export function SecretaryReviewFormContainer({ state, updateState, onChange }: any) {
  const { axios } = useContext<AxiosContextType>(AxiosContext);
  const { candidateFileContext } = useContext<CandidateFileContextProviderType>(CandidateFileContext);
  const entity = useScopedSelector<any[], string>('SecretaryReviewFormContainer', EntityFormContext, (c) => c[0]?.entity);

  async function onChangeParticularCase(particularCase: boolean) {
    await updateState((draft: any) => {
      draft.secretaryReview.particularCase = particularCase;
    });
    await axios.patch(`/candidate-file/${candidateFileContext.selectedCandidateFile?.id}`, { particularCase });
  }

  async function onChangeIgnoreIt(ignoreIt: boolean) {
    await updateState((draft: any) => {
      draft.secretaryReview.ignoreIt = ignoreIt;
    });
    await axios.patch(`/candidate-file/${candidateFileContext.selectedCandidateFile?.id}`, { ignoreIt });
  }

  async function onStatusChange(e: any) {
    const valueState = e?.target?.value;
    if (valueState !== state.secretaryReview.state) {
      await updateState((draft: any) => {
        draft.secretaryReview.state = valueState;
      });
      onChange?.({ id: state.secretaryReview.id, state: valueState });
    }
  }

  const { userCtx } = useContext(UserContext);

  return (
    <HeaderStyle>
      <HideWhenSmallScreen>
        <Locale.Label tkey={state.status} />
      </HideWhenSmallScreen>
      <div css={css`.translation{margin-top: 5px !important}`}>
        <DisabledContextProvider value={{ disabled: entity === 'programs-list' && !isSuperSecretary(userCtx), wrapAuthorized: true }}>
          <Form.Item style={{ minWidth: '300px' }} label={<Locale.Label tkey="secretaryReview.status" />}>
            <RadioButtonGroup
              disabled={entity === 'programs-list' && !isSuperSecretary(userCtx)}
              onChange={onStatusChange}
              value={state.secretaryReview.state}
              buttonStyle="solid"
            >
              <Colorized color="#ccc">
                <Radio.Button
                  value={SecretaryReviewStatusEnum.UNDEFINED}
                >
                  <Locale.Button
                    tkey="secretaryReview.status.valid.undefined"
                  />
                </Radio.Button>
              </Colorized>
              <Colorized color="#ff4d4f">
                <Radio.Button
                  disabled={entity === 'payments-list' && !isSuperSecretary(userCtx)}
                  value={SecretaryReviewStatusEnum.NOT_VALID}
                >
                  <Locale.Button
                    tkey="secretaryReview.status.valid.false"
                  />
                </Radio.Button>
              </Colorized>
              <Colorized color="#52c41a">
                <Radio.Button value={SecretaryReviewStatusEnum.VALID}>
                  <Locale.Button
                    tkey="secretaryReview.status.valid.true"
                  />
                </Radio.Button>
              </Colorized>
            </RadioButtonGroup>
          </Form.Item>
        </DisabledContextProvider>
      </div>
      <Form.Item style={{ textAlign: 'right' }}>
        <Colorized color="#663399">
          <Switch
            style={{ width: '150px' }}
            size="default"
            onChange={onChangeParticularCase}
            defaultChecked={state.particularCase}
            checkedChildren={(
              <Locale.Button
                tkey="secretaryReview.particular.true"
              />
            )}
            unCheckedChildren={(
              <Locale.Button
                tkey="secretaryReview.particular.false"
              />
            )}
          />
        </Colorized>
      </Form.Item>
      <Form.Item style={{ maxWidth: '150px' }}>
        <Colorized color="#ff4d4f">
          <Switch
            size="default"
            style={{ width: '150px' }}
            onChange={onChangeIgnoreIt}
            defaultChecked={state.ignoreIt}
            checkedChildren={(
              <Locale.Button
                tkey="secretaryReview.ignoreIt.true"
              />
            )}
            unCheckedChildren={(
              <Locale.Button
                tkey="secretaryReview.ignoreIt.false"
              />
            )}
          />
        </Colorized>
      </Form.Item>
    </HeaderStyle>
  );
}

const HeaderStyle = styled.div`

    gap: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
   >div{
    justify-content: center;
    }
  .ant-radio-group{
    overflow: hidden;
    white-space: nowrap;
    justify-content: center;
    >div{
        overflow: hidden;
        white-space: nowrap;
    }
  }
    .ant-form-item {
        flex-direction: row !important;
        flex-wrap: nowrap;
        flex: 1;
        .ant-form-item-label,.ant-form-item-control{
          flex: 1;
          }
    }
`;
