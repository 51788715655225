import { Canvas, View } from '@react-pdf/renderer';
import React, { Fragment } from 'react';
import { Container } from '../../../common/pdf/components/PDFCard';
import { PDFH1 } from '../../../common/pdf/components/PDFH1';
import { PDFText } from '../../../common/pdf/components/PDFText';
import { PDFTextGutter } from '../../../common/pdf/components/PDFTextGutter';
import { CandidateFileForExport } from './export.type';
import { PDFSpacer } from '../../../common/pdf/components/PDFSpacer';

export function OtherInformationPDF({ otherInformation }: { otherInformation: CandidateFileForExport }) {
  if (!otherInformation) {
    return <PDFH1>Autres informations</PDFH1>;
  }
  return (
    <Container>

      <PDFH1>Autres informations</PDFH1>

      <PDFTextGutter>
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Cas particulier :</PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.CandidateFile?.particularCase ? 'Oui ' : 'Non'}</PDFText>
        </View>
      </PDFTextGutter>
      <PDFTextGutter>
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Non éligible :</PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.CandidateFile?.ignoreIt ? 'Oui ' : 'Non'}</PDFText>
        </View>
      </PDFTextGutter>
      <PDFTextGutter>
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Numéro INE :</PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.Bachelor?.ine?.replace('@@', '')}</PDFText>
        </View>
      </PDFTextGutter>
      <PDFTextGutter>
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Boursier :</PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.PaymentsList?.funded ? 'Oui ' : 'Non'}</PDFText>
        </View>
      </PDFTextGutter>
      <PDFTextGutter>
        <PDFSpacer />
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Avis PE inséré :</PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.AcademicsList?.finalReview}</PDFText>
        </View>
        <PDFSpacer />
      </PDFTextGutter>
      <PDFTextGutter>
        <PDFSpacer />
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Absences injustifiées :
          </PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.AcademicsList?.unjustifiedAbsences && ' Oui'}
            {otherInformation.AcademicsList?.unjustifiedAbsences === null && ' '}
            {otherInformation.AcademicsList?.unjustifiedAbsences === false && ' Non'}
          </PDFText>
        </View>
        <PDFSpacer />
      </PDFTextGutter>
      <PDFTextGutter>
        <PDFSpacer />
        <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
        <View style={{ width: '25%', fontSize: 10 }}>
          <PDFText>Comportement à problème :
          </PDFText>
        </View>
        <View style={{ width: '70%' }}>
          <PDFText>{otherInformation.AcademicsList?.problemBehaviorsMentioned && ' Oui'}
            {otherInformation.AcademicsList?.problemBehaviorsMentioned === null && ' '}
            {otherInformation.AcademicsList?.problemBehaviorsMentioned === false && ' Non'}
          </PDFText>
        </View>
        <PDFSpacer />
      </PDFTextGutter>
      {!!otherInformation.CandidateFile?.commentsBtwAllTypesOfSecretaries && (
        <Fragment>
          <Canvas style={{ width: '100%', height: '5px' }} paint={(painter, availableWidth) =>
            painter.moveTo(30, 0)
              .lineTo(availableWidth - 30, 0)
              .lineTo(availableWidth - 30, 2)
              .lineTo(30, 2)
              .fill('#CCCCCC')}
          />
          <PDFTextGutter>
            <PDFSpacer />
            <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
            <View style={{ width: '22%', alignSelf: 'baseline', fontSize: 10 }}>
              <PDFText>Commentaire instruction : </PDFText>
            </View>
            <View style={{ width: '73%' }}>
              <PDFText>{otherInformation.CandidateFile?.commentsBtwAllTypesOfSecretaries}</PDFText>
            </View>
            <PDFSpacer />
          </PDFTextGutter>
        </Fragment>
      )}
      {!!otherInformation.CandidateFile?.specializedSecretaryReviews && (
        <Fragment>
          <Canvas style={{ width: '100%', height: '5px' }} paint={(painter, availableWidth) =>
            painter.moveTo(30, 0)
              .lineTo(availableWidth - 30, 0)
              .lineTo(availableWidth - 30, 2)
              .lineTo(30, 2)
              .fill('#CCCCCC')}
          />
          <PDFTextGutter>
            <PDFSpacer />
            <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
            <View style={{ width: '22%', alignSelf: 'baseline', fontSize: 10 }}>
              <PDFText>Commentaire formation : </PDFText>
            </View>
            <View style={{ width: '73%' }}>
              <PDFText>{otherInformation.CandidateFile?.specializedSecretaryReviews}</PDFText>
            </View>
            <PDFSpacer />
          </PDFTextGutter>
        </Fragment>
      )}
      {!!otherInformation.CandidateFile?.missingFieldsList?.missingFields && (
        <Fragment>
          <Canvas style={{ width: '100%', height: '5px' }} paint={(painter, availableWidth) =>
            painter.moveTo(30, 0)
              .lineTo(availableWidth - 30, 0)
              .lineTo(availableWidth - 30, 2)
              .lineTo(30, 2)
              .fill('#CCCCCC')}
          />
          <PDFTextGutter>
            <PDFSpacer />
            <View style={{ width: '5%' }}><PDFText>{' '}</PDFText></View>
            <View style={{ width: '20%', alignSelf: 'baseline', fontSize: 10 }}>
              <PDFText>Pièces manquantes : </PDFText>
            </View>
            <View style={{ width: '75%' }}>
              <PDFText>{otherInformation.CandidateFile?.missingFieldsList?.missingFields}</PDFText>
            </View>
            <PDFSpacer />
          </PDFTextGutter>
        </Fragment>
      )
      }

    </Container >
  );
}
