/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { HideWhenSmallScreen } from '../../withExtraParameters/HideWhenSmallScreen';
import { copyEntityFormProps, EntityFormItemContainer } from '../entityForm/EntityFormItemContainer';
import { SecretaryReviewFormItemContent } from './SecretaryReviewFormItemContent';
import { useScopedSelector } from '../../../hooks/use-scoped-selector';
import { EntityFormContext } from '../../../context/entityForm.context';
import { CanSeeValidation } from '../../conditionnal/validation/CanSeeValidation';
import { DisabledContextProviderAlwaysEnabled } from '../../rights/DisabledContextProviderAlwaysEnabled';

export function SecretaryReviewFormFooterContainer(props: { propertyName: string | any[] }) {
    const candidateFileStatusId = useScopedSelector<any[], string>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.status?.id);
    const ignoreIt = useScopedSelector<any[], boolean>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.ignoreIt);
    const secretaryReviewId = useScopedSelector<any[], number>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.secretaryReview?.id);
    const secretaryReviewState = useScopedSelector<any[], any>('SecretaryReviewFormFooterContainer_secretaryReviewStatus', EntityFormContext,
        (c) => c[0]?.entityValue.secretaryReview?.state);
    const particularCase = useScopedSelector<any[], boolean>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.particularCase);
    const commentsBtwAllTypesOfSecretaries = useScopedSelector<any[], any>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.commentsBtwAllTypesOfSecretaries);
    const commentsBtwSecretaries = useScopedSelector<any[], any>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.commentsBtwSecretaries);
    const commentsBtwSecretaryAndCandidate = useScopedSelector<any[], any>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.commentsBtwSecretaryAndCandidate);
    const missingFieldsList = useScopedSelector<any[], any>('SecretaryReviewFormFooterContainer', EntityFormContext,
        (c) => c[0]?.entityValue.candidateFile?.missingFieldsList);

    return (
      <div css={css`position:fixed;bottom:0; left: 160px;right: 80px;`}>
        <CanSeeValidation css={css` z-index:10`}>
          <HideWhenSmallScreen>
            <div css={css`  
                .ant-form-item-children-icon{
                  display: none !important ;
                }
`}
            >
              <DisabledContextProviderAlwaysEnabled>
                <EntityFormItemContainer
              // disabled={false}
                  wrapperCol={{ span: 24 }}
                  {...copyEntityFormProps(props)}
                  label=""
                >
                  <SecretaryReviewFormItemContent
                    candidateFileStatusId={candidateFileStatusId}
                    commentsBtwAllTypesOfSecretaries={commentsBtwAllTypesOfSecretaries}
                    commentsBtwSecretaries={commentsBtwSecretaries}
                    commentsBtwSecretaryAndCandidate={commentsBtwSecretaryAndCandidate}
                    ignoreIt={ignoreIt}
                    particularCase={particularCase}
                    missingFieldsList={missingFieldsList}
                    secretaryReviewId={secretaryReviewId}
                    secretaryReviewState={secretaryReviewState}
                  />
                </EntityFormItemContainer>
              </DisabledContextProviderAlwaysEnabled>
            </div>
          </HideWhenSmallScreen>
        </CanSeeValidation>
      </div>
    );
}
