/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import * as Locale from '../../locale';

/**
 * @see FormContentPanelContainer
 * @param partName
 * @param children
 * @param compact
 * @param entity
 * @constructor
 */
export function FormContentPanel({
 partName, children, compact, entity, extra,
}:any) {
    /* console.log('Redraw FormContentPanel'); */
    return (
      <article id={partName} className="message  is-small">
        {!compact && (
        <div className="message-header" css={css`display: flex;gap:1em`}>
          <Locale.Title tkey={`${entity}.${partName}`} />
            {extra}
        </div>
)}

        {!compact && (
        <div className="message-body">
          <Locale.Content tkey={`${entity}.${partName}`} />
        </div>
)}

        {!!children && !!children.map && children.map((c: any, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="message-block">
            {c}
          </div>
          ))}
        {!!children && !children.map
          && (
          <div className="message-block">
            {children}
          </div>
)}
      </article>
);
}
