/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useContext } from 'react';
import { Importer, ImporterField } from 'react-csv-importer-v2';

/**
 * Page pour lancer l'import des status d'avis jury donnes par le jury a certains dossiers
 * Las dossiers, dont le shortId est passe en premiere colonne, voient leur avis jury mis à jour avec le statut en 2nde colonne 
 */
import 'react-csv-importer-v2/dist/index.css';
import { useImmer } from 'use-immer';
import { AxiosContext, AxiosContextType } from '../../context/axios.context';
import { ResultPanel } from '../admin/common/ResultPanel';
import Title from 'antd/lib/typography/Title';
import * as Locale from '../../common/locale';
import { Divider } from 'antd';
import { useSafeTranslation } from '../../hooks/use-safe-translation';

/**
 * Permet de generer le rapport d'importation a partir de l'objet contenant le compte rendu de l'importation
 * Note : Seul le nombre de maj effectives est affiche systematiquement. Les autres informations ne le sont que s'il y a lieu (<> 0 et listes contenant au moins un element)
 * TODO : passer l'objet en dto
 * @param data Un objet contenant le compte rendu de l'importation :
     *              - nbUpdateJuryReview : nombre de mises a jour effectives
     *              - nbIgnored : nombre de mises a jour ignorees pour statut inchange
     *              - listBadCfId : liste des ids de dossiers inexistants ou non rattaches au jury avec numero de ligne correspondant dans le csv {line - id}
     *              - listBadStatusId : liste des statut d'avis jury errones avec numero de ligne correspondant dans le csv {line - status}
 * @returns 
 */
export function FormatResult(data: any): any {
  const { t } = useSafeTranslation();

  if (data) {
    return (
      <div>
        <Locale.Content tkey='nbUpdateJuryReview' /> : {data?.nbUpdateJuryReview}
        {(data?.nbIgnored > 0) && (
          <div>
            <Locale.Content tkey='nbIgnoredJuryReview' /> : {data?.nbIgnored}
          </div>
        )}
        {(data?.listBadCfId?.length > 0) && (
          <div>
            <br />
            <h3 className='h3'><Locale.Header tkey='badCFIdList' /></h3>
            <ul css={css`padding-left: 30px`}>
              {(data?.listBadCfId?.map((elem) => {
                return (
                  <li css={css`list-style-type: disc`}> {`${t('linenumber.label')} ${elem.line} ${t('badid.label')} ${elem.id}`}
                  </li>)
              }))}
            </ul>
          </div>
        )}
        {(data?.listBadStatusId?.length > 0) && (
          <div>
            <br />
            <h3 className='h3'><Locale.Header tkey='badStatusId' /></h3>
            <ul css={css`padding-left: 30px`}>
              {(data?.listBadStatusId?.map((elem) => {
                return (
                  <li css={css`list-style-type: disc`}> {`${t('linenumber.label')} ${elem.line} ${t('badstatus.label')} ${elem.id}`}
                  </li>)
              }))}
            </ul>
          </div>
        )}
      </div>
    )
  }
  else {
    return undefined;
  }
}

export function JuryStatusImporter() {
  const [state, updateState] = useImmer({ values: [], error: undefined as string, result: undefined as string });
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  return (
    <div css={css`margin: 20px`}>
      <Title level={2}><Locale.Title tkey="importJuryStatus" /></Title>
      <Locale.Title tkey="explanationImportJuryStatus" />
      <Divider />
      <Importer
        delimiter=";"
        onStart={() => {
        }}
        processChunk={async (rows) => {
          updateState((draft) => {
            draft.values = [...draft.values, ...rows];
          });
        }}
        onComplete={async () => {
          try {
            const response: any = await axios?.post('jury-review/import', {
              bulk: state.values,
            });
            updateState((draft) => {
              draft.result = response.data;
              draft.error = undefined;
            });
          } catch (ex) {
            updateState((draft) => {
              draft.error = ex.response.data.message;
              draft.result = undefined;
            });
          }
        }}
      >
        <ImporterField name="id" label="id" />
        <ImporterField name="status" label="status" />
      </Importer>
      <br />
      <ResultPanel error={state.error} result={FormatResult(state.result)} />
    </div>
  );
}
